import React from "react";
import loaderConnect from '../../Components/Loader/loaderConnect';
import ListManager from "../../Components/ListManager/listManager";
import { apiGetResidentes } from '../../API/api';
import Filtros from './filtros';

const ResidentsList = (props) => {



    const mapData = (data, handleEdit, handleDetail) => {
        let datatable = [];

        datatable = data.map(elm => ({
            id: elm.codigo_residente? elm.codigo_residente: "N/A",
            nombre: elm.nombres + ' ' + elm.apellidos,
            tel: elm.telefono,
            titular: elm.suscripcion ? elm.suscripcion.residente_titular.codigo_residente : <i className="fas fa-user-check" />,
            suscripcion: elm.suscripcion ? elm.suscripcion.detalle_suscripcion.nombre : "Sin suscripción",
            activo: elm.activo ?  <i className="fas fa-check" /> :  <i className="fas fa-times" />,
            opciones: <div className="btn-container">
                <button onClick={() => handleEdit(elm)} className="btn btn-success"><i className="fas fa-edit" /> Editar</button>
                <button onClick={() => handleDetail(elm)} className="btn btn-primary"><i className="fas fa-list" /> Detalles</button>
            </div>
        }))

        return datatable;
    }

    return <ListManager
        title="Usuarios"
        icon="fas fa-users"
        link="/usuarios/nuevo"
        btnNewText="Nuevo usuario"
        tableOptions={{
            columns: ["Código", { name: "Nombre", className: "tal" }, "Teléfono", "Titular", "Suscripción","Activo", "Opciones"],
            pagination: true,
            configTable: {
                columnDefinition: {},
                columnStyle: { nombre: { textAlign: 'left' }, opciones: { textAlign: 'right' } }
            }
        }}
        mapData={mapData}
        Filters={Filtros}
        location={{ state: null }}
        GetData={apiGetResidentes}
        {...props}
        keyLabel="id_residente"
    />


}

export default loaderConnect(ResidentsList)