import React from 'react';
import { Link } from 'react-router-dom';
import ModalInhabilitar from './modalInhabilitar';
import Switch from "react-switch";
import { apiUpdateCancha } from '../../API/apiCanchas';

const DetailArea = ({ selectedCancha, showLoader, NotificationManager, updateCanchas }) => {

  const [cancha, setCancha] = React.useState(selectedCancha);

  const [showModal, setShowModal] = React.useState(false);

  React.useEffect(() => {
    setCancha(selectedCancha);
  }, [selectedCancha]);

  const changeSwitch = () => {
    setShowModal(true);
  }

  const handleClose = () => {
    setShowModal(false);
  }

  const Confirm = () => {
    showLoader(true);
    apiUpdateCancha(cancha.id_area, { activo: !cancha.activo })
      .then(response => {
        if (!response.message) {
          setCancha(response);
          NotificationManager.success("Cancha actualizada correctamente.", "¡Éxito!", 5000);
          handleClose();
          updateCanchas();
        }
        else {
          NotificationManager.error("No se ha podido actualizar la cancha", "¡Error!", 5000);
        }
      })
      .catch(() => NotificationManager.error("No se ha podido actualizar la cancha", "¡Error!", 5000))
      .finally(() => showLoader(false))
  }

  return <div>
    <div className="row">
      <div className="col-md-8 col-12">
        <label className="title">{cancha.nombre}</label>
      </div>
      <div className="col tar pd-0">
        {
          cancha.protegida ?
            <label>Protegida</label>
            :

            <Switch
              checked={cancha.activo}
              onChange={(value) => changeSwitch('activo', value)}
              onColor="#1DC775"
              offColor="#DD6E07"
              onHandleColor="#E0E0E0"
              handleDiameter={10}
              height={18}
              width={40}
              // uncheckedIcon={<i className="fas fa-times"/>}
              // checkedIcon={<i className="fas fa-check"/>}
              className="react-switch"
              id={"activo"}
            />
        }
      </div>
    </div>
    <div className="tac">
      <label className="title" style={{ fontSize: "14px", display: "inline" }}>Puerta: </label>
      <label>{cancha.puerta}</label>
    </div>
    <p className="tac">{cancha.descripcion}</p>
    <ModalInhabilitar
      show={showModal}
      name={cancha.nombre}
      type={cancha.activo ? "inhabilitar" : "habilitar"}
      handleClose={handleClose}
      confirm={Confirm}
    />
  </div>
}

export default DetailArea;