import React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  WafoForm,
  WafoFormAutocomplete,
  WafoFormSelect,
  WafoFormMultiSelect,
  WafoFormInput,
} from "@wafo/forms";
import {
  apiGetResidentes,
  apiGetTiposJuego,
  apiNewClase,
  apiNewReservacion,
} from "../../API/api";
import { apiGetCanchas } from "../../API/apiCanchas";
import DatetimePicker from "../../Components/customInputs/datetimePicker/datetimePicker";
import moment from "moment";
import Dayofweek from "../../Components/customInputs/dayofweek/dayofweek";

const NewReservacion = ({ showLoader, NotificationManager }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { id: idRerservacion } = useParams();

  const isEditMode = React.useMemo(() => {
    return pathname.includes("editar");
  }, [pathname]);

  // reservacion | clase
  const [mode, setMode] = React.useState("reservacion");

  const [form, setForm] = React.useState({
    principal: undefined,
    tipo: undefined,
  });
  const [residentes, setResidentes] = React.useState([]);
  const [canchas, setCanchas] = React.useState([]);
  const [tipos, setTipos] = React.useState([]);

  React.useEffect(() => {
    async function getResidentes() {
      showLoader(true);
      try {
        const resp = await apiGetResidentes({
          page: 1,
          limit: 999999,
        });
        setResidentes(
          resp.rows.filter(
            (x) => x.suscripcion.detalle_suscripcion.id_suscripcion !== 1
          )
        );
      } catch (error) {
        console.error(error);
      }
      showLoader(false);
    }
    async function getCanchas() {
      showLoader(true);
      try {
        const resp = await apiGetCanchas({
          page: 1,
          limit: 999999,
        });
        setCanchas(resp.rows);
      } catch (error) {
        console.error(error);
      }
      showLoader(false);
    }
    async function getTipos() {
      showLoader(true);
      try {
        const resp = await apiGetTiposJuego({
          page: 1,
          limit: 999999,
        });
        setTipos(resp.rows);
      } catch (error) {
        console.error(error);
      }
      showLoader(false);
    }

    getResidentes();
    getCanchas();
    getTipos();
  }, [showLoader]);

  const filteredResidentes = React.useMemo(() => {
    if (!form.principal)
      return residentes.filter(
        (x) => x.suscripcion.detalle_suscripcion.id_suscripcion !== 2
      );
    return residentes.filter(
      (x) =>
        x.suscripcion.detalle_suscripcion.id_suscripcion !== 2 &&
        x.id_residente !== form.principal.id_residente
    );
  }, [form.principal, residentes]);

  const maestros = React.useMemo(() => {
    return residentes.filter(
      (x) => x.suscripcion.detalle_suscripcion.id_suscripcion === 2
    );
  }, [residentes]);

  const maestroRequired = React.useMemo(() => {
    const tipo = tipos.find((x) => x.id_tipo_juego == form.tipo);
    if (!tipo) return true;
    return !tipo.numero_maestros > 0;
  }, [form.tipo, tipos]);

  const handleSubmitReservacion = async (form, values) => {
    if (form.valid) {
      showLoader(true);
      try {
        const reservacion = {
          id_residente: values.id_residente.id_residente,
          id_area: values.id_area,
          tipo_juego: values.tipo_juego,
          invitados: [
            ...values.invitados
              .filter((x) => x.id_residente !== undefined)
              .map((x) => x.id_residente),
            ...(values.maestro && [values.maestro.id_residente]),
          ],
          fecha: [moment(values.fecha).toISOString()],
        };
        const resp = await apiNewReservacion(reservacion);
        if (resp.message) {
          NotificationManager.error(resp.message, "¡Error!", 5000);
        } else {
          NotificationManager.success("Reservación creada.", "¡Exito!", 5000);
          history.push("/canchas")
        }
      } catch (error) {
        console.error(error);
        NotificationManager.error(
          "Ocurrió un error al guardar los datos.",
          "¡Error!",
          5000
        );
      }
      showLoader(false);
    }
  };

  const handleSubmitClase = async (form, values) => {
    if (form.valid) {
      showLoader(true);
      try {
        const hora_inicio = parseInt(values.hora.split(":")[0]);

        const clase = {
          id_residente: values.maestro.id_residente,
          id_area: values.id_area,
          tipo_juego: 3,
          invitados: values.invitados
            .filter((x) => x.id_residente !== undefined)
            .map((x) => x.id_residente),
          fecha_inicio: values.fecha_inicio,
          fecha_fin: values.fecha_fin,
          dias: [
            {
              dia: values.week,
              hora_inicio: hora_inicio,
              hora_fin: hora_inicio >= 23 ? 0 : hora_inicio + 1,
              time_zone: "America/Hermosillo",
            },
          ],
        };

        const resp = await apiNewClase(clase);
        if (resp.message) {
          NotificationManager.error(resp.message, "¡Error!", 5000);
        } else {
          NotificationManager.success("Clase fija creada.", "¡Exito!", 5000);
          history.push("/canchas")
        }
      } catch (error) {
        console.error(error);
        NotificationManager.error(
          "Ocurrió un error al guardar los datos.",
          "¡Error!",
          5000
        );
      }
      showLoader(false);
    }
  };

  return (
    <div>
      <div className="mg-top">
        <h1>
          <i className="fas fa-id-card" /> Reservaciones
        </h1>
      </div>
      <div className="card mg-top">
        <div className="card-body">
          <h3 onClick={() => history.goBack()}>
            <i className="fas fa-arrow-left" />{" "}
            {isEditMode ? "Editar" : "Nueva"} reservacion
          </h3>

          <div style={{ paddingTop: "1.5rem" }} />

          <div className="btn-group" role="group" aria-label="Basic example">
            <button
              type="button"
              className={`btn ${mode === "reservacion" ? "btn-primary" : "btn-light"
                }`}
              onClick={() => setMode("reservacion")}
            >
              Reservación
            </button>
            <button
              type="button"
              className={`btn ${mode === "clase" ? "btn-primary" : "btn-light"
                }`}
              onClick={() => setMode("clase")}
            >
              Clase fija
            </button>
          </div>

          <div style={{ paddingTop: "1.5rem" }} />

          {mode === "reservacion" && (
            <WafoForm
              formId="form-reservacion"
              locale="es"
              onSubmit={handleSubmitReservacion}
              values={{}}
            >
              <WafoFormSelect
                name="id_area"
                label="Espacio"
                defaultValue="Seleccione espacio"
                customClass="col-12 col-md-4"
                options={canchas.map((c) => ({
                  display: c.nombre,
                  value: c.id_area,
                }))}
                validations={{ required: true }}
              />

              <WafoFormSelect
                name="tipo_juego"
                label="Tipo de juego"
                defaultValue="Seleccione tipo"
                customClass="col-12 col-md-4"
                options={tipos.map((t) => ({
                  display: t.nombre,
                  value: t.id_tipo_juego,
                }))}
                validations={{ required: true }}
                onChangeCallback={({ target: { value } }) =>
                  setForm((prev) => ({ ...prev, tipo: value }))
                }
              />

              <WafoFormAutocomplete
                name="id_residente"
                customClass="col-12 col-md-4"
                label="Jugador principal"
                placeholder="Seleccione jugador principal"
                items={residentes}
                filterItems={(items, query) =>
                  items.filter(
                    (item) =>
                      item.nombres
                        .toLowerCase()
                        .indexOf(query.toLowerCase()) !== -1 ||
                      item.apellidos
                        .toLowerCase()
                        .indexOf(query.toLowerCase()) !== -1 ||
                      item.codigo_residente
                        .toLowerCase()
                        .indexOf(query.toLowerCase()) !== -1
                  )
                }
                renderInput={(item) =>
                  `[${item.codigo_residente}] - ${item.nombres} ${item.apellidos}`
                }
                renderItem={(item) => (
                  <p
                    className="autocomplete-item"
                    style={{ padding: "0.5rem 1rem", margin: 0, lineHeight: 1 }}
                  >
                    <span>
                      {item.nombres} {item.apellidos}
                    </span>
                    <br />
                    <span style={{ fontSize: "0.85em", color: "#777" }}>
                      Código: {item.codigo_residente}
                    </span>
                  </p>
                )}
                validations={{ required: true }}
                extraProps={{
                  autoComplete: "off",
                  // disabled: supervisor,
                }}
                onSelectCallback={(val) =>
                  setForm((prev) => ({ ...prev, principal: val }))
                }
                handleChange
              />

              <WafoFormMultiSelect
                name="invitados"
                customClass="col-12 col-md-4"
                label="Invitados"
                placeholder="Elija invitados"
                itemKey="id_residente"
                items={filteredResidentes}
                renderInput={(item) =>
                  `[${item.codigo_residente}] - ${item.nombres} ${item.apellidos}`
                }
                renderItem={(item) => (
                  <p
                    className="autocomplete-item"
                    style={{ padding: "0.5rem 1rem", margin: 0, lineHeight: 1 }}
                  >
                    <span>
                      {item.nombres} {item.apellidos}
                    </span>
                    <br />
                    <span style={{ fontSize: "0.85em", color: "#777" }}>
                      Código: {item.codigo_residente}
                    </span>
                  </p>
                )}
                validations={{
                  required: true,
                  validationFunction: {
                    value: (val) => {
                      if (!val) return false;
                      const tipo = tipos.find(
                        (x) => x.id_tipo_juego == form.tipo
                      );
                      if (!tipo) return false;
                      return (
                        val.filter((x) => x.id_residente !== undefined)
                          .length === tipo.numero_invitados
                      );
                    },
                    message: "El número de invitados no coincide con el tipo.",
                  },
                }}
              />

              <WafoFormAutocomplete
                name="maestro"
                customClass="col-12 col-md-4"
                label="Maestro / Instructor"
                placeholder="Seleccione maestro"
                items={maestros}
                filterItems={(items, query) =>
                  items.filter(
                    (item) =>
                      item.nombres
                        .toLowerCase()
                        .indexOf(query.toLowerCase()) !== -1 ||
                      item.apellidos
                        .toLowerCase()
                        .indexOf(query.toLowerCase()) !== -1 ||
                      item.codigo_residente
                        .toLowerCase()
                        .indexOf(query.toLowerCase()) !== -1
                  )
                }
                renderInput={(item) =>
                  `[${item.codigo_residente}] - ${item.nombres} ${item.apellidos}`
                }
                renderItem={(item) => (
                  <p
                    className="autocomplete-item"
                    style={{ padding: "0.5rem 1rem", margin: 0, lineHeight: 1 }}
                  >
                    <span>
                      {item.nombres} {item.apellidos}
                    </span>
                    <br />
                    <span style={{ fontSize: "0.85em", color: "#777" }}>
                      Código: {item.codigo_residente}
                    </span>
                  </p>
                )}
                extraProps={{
                  autoComplete: "off",
                  disabled: maestroRequired,
                }}
                handleChange
                validations={{
                  required: !maestroRequired,
                }}
              />

              <DatetimePicker
                name="fecha"
                customClass="col-12 col-md-4"
                label="Fecha y hora"
                validations={{ required: true }}
                handleChange
                noMinutes
              />

              <div className="col-12">
                <div className="mg-top tac">
                  <button type="submit" className="btn btn-success">
                    Guardar reservación
                  </button>
                </div>
              </div>
            </WafoForm>
          )}

          {mode === "clase" && (
            <WafoForm
              formId="form-clase"
              locale="es"
              onSubmit={handleSubmitClase}
              values={{}}
            >
              <WafoFormSelect
                name="id_area"
                label="Espacio"
                defaultValue="Seleccione espacio"
                customClass="col-12 col-md-4"
                options={canchas.map((c) => ({
                  display: c.nombre,
                  value: c.id_area,
                }))}
                validations={{ required: true }}
              />

              <WafoFormAutocomplete
                name="maestro"
                customClass="col-12 col-md-4"
                label="Maestro / Instructor"
                placeholder="Seleccione maestro"
                items={maestros}
                filterItems={(items, query) =>
                  items.filter(
                    (item) =>
                      item.nombres
                        .toLowerCase()
                        .indexOf(query.toLowerCase()) !== -1 ||
                      item.apellidos
                        .toLowerCase()
                        .indexOf(query.toLowerCase()) !== -1 ||
                      item.codigo_residente
                        .toLowerCase()
                        .indexOf(query.toLowerCase()) !== -1
                  )
                }
                renderInput={(item) =>
                  `[${item.codigo_residente}] - ${item.nombres} ${item.apellidos}`
                }
                renderItem={(item) => (
                  <p
                    className="autocomplete-item"
                    style={{ padding: "0.5rem 1rem", margin: 0, lineHeight: 1 }}
                  >
                    <span>
                      {item.nombres} {item.apellidos}
                    </span>
                    <br />
                    <span style={{ fontSize: "0.85em", color: "#777" }}>
                      Código: {item.codigo_residente}
                    </span>
                  </p>
                )}
                extraProps={{
                  autoComplete: "off",
                }}
                handleChange
                validations={{
                  required: true,
                }}
              />

              <WafoFormMultiSelect
                name="invitados"
                customClass="col-12 col-md-4"
                label="Invitados"
                placeholder="Elija invitados"
                itemKey="id_residente"
                items={filteredResidentes}
                renderInput={(item) =>
                  `[${item.codigo_residente}] - ${item.nombres} ${item.apellidos}`
                }
                renderItem={(item) => (
                  <p
                    className="autocomplete-item"
                    style={{ padding: "0.5rem 1rem", margin: 0, lineHeight: 1 }}
                  >
                    <span>
                      {item.nombres} {item.apellidos}
                    </span>
                    <br />
                    <span style={{ fontSize: "0.85em", color: "#777" }}>
                      Código: {item.codigo_residente}
                    </span>
                  </p>
                )}
                validations={{
                  required: true,
                  validationFunction: {
                    value: (val) => {
                      if (!Array.isArray(val)) return false;
                      const alumnos = val.filter(
                        (x) => x.id_residente !== undefined
                      ).length;
                      return alumnos >= 1 && alumnos <= 5;
                    },
                    message: "La clase debe tener entre 1 y 5 alumnos.",
                  },
                }}
              />

              <Dayofweek
                name="week"
                label="Día de la semana"
                customClass="col-12 col-md-12"
                validations={{
                  required: true,
                }}
                handleChange
              />

              <WafoFormInput
                type="date"
                name="fecha_inicio"
                label="Fecha inicio"
                customClass="col-12 col-md-4"
                validations={{
                  required: true,
                }}
              />

              <WafoFormInput
                type="date"
                name="fecha_fin"
                label="Fecha fin"
                customClass="col-12 col-md-4"
                validations={{
                  required: true,
                }}
              />

              <WafoFormInput
                type="time"
                name="hora"
                label="Hora"
                customClass="col-12 col-md-4"
                validations={{
                  required: true,
                }}
                extraProps={{
                  step: "3600",
                }}
              />

              <div className="col-12">
                <div className="mg-top tac">
                  <button type="submit" className="btn btn-success">
                    Guardar clase
                  </button>
                </div>
              </div>
            </WafoForm>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewReservacion;
