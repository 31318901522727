import React from 'react';
import { Modal } from 'react-bootstrap';

const ModalInhabilitar = (props) => {
  const { show, handleClose, confirm, name, type } = props;

  const handleCloseModal = () => {
    handleClose();
  }

  const handleConfirm = () => {
    confirm();
  }

  return (
    <Modal show={show} onHide={handleCloseModal} centered
      dialogClassName="modal-20w">
      <Modal.Header closeButton>
        <Modal.Title>Confirmar  {type}</Modal.Title>
      </Modal.Header>
      <Modal.Body >
        <div className="tac" style={{ fontSize: "20px" }}>
          <label> ¿Quieres {type} la cancha <b>{name}</b>?</label>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary" onClick={handleCloseModal}>No, cerrar</button>
        <button className="btn btn-danger" onClick={handleConfirm}>Si, {type}</button>
      </Modal.Footer>
    </Modal>
  );
}

ModalInhabilitar.defaultProps = {
  show: false,
  name: '',
  type: "inhabilitar",
  confirm: () => { },
  handleClose: () => { },
}

export default ModalInhabilitar;