import React, { useEffect, useCallback, useReducer } from "react";
import { Link, withRouter } from 'react-router-dom';
import loaderConnect from '../../Components/Loader/loaderConnect';
import Pagination from '../../Components/Pagination/pagination';
import Table from '../../Components/ListManager/table';
import { apiGetAvisos, apiDeleteAviso } from '../../API/apiAvisos';
import ModalEliminarAviso from './modalEliminar';

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_DATA":
      return { ...state, data: action.payload }
    case 'SET_PAGE':
      return { ...state, page: action.payload }
    case "SET_FILTERS":
      return { ...state, filters: action.payload }
    case "SET_MODAL":
      return { ...state, modal: action.payload }
    default:
      return { ...state }
  }
}

const Avisos = (props) => {
  const initialState = {
    filters: {},
    data: { count: 0, rows: [] },
    page: props.location.state ? props.location.state.page : 1,
    modal: { show: false, obj: {}, name: '' }
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const { data, page, filters, modal } = state;
  const { showLoader, NotificationManager } = props;

  useEffect(() => {
    showLoader(true);
    apiGetAvisos({ page, ...filters })
      .then(response => {
        if (response) {
          if (!response.message) {
            dispatch({ type: "SET_DATA", payload: response })
          }
        }
      })
      .finally(r => {
        showLoader(false);
      })
  }, [page, filters, showLoader]);

  const handleShowModal = (elm, inative) => {
    dispatch({ type: "SET_MODAL", payload: { show: true, name: elm.titulo, obj: elm } });
  }

  const handleClose = () => {
    dispatch({ type: "SET_MODAL", payload: { show: false, name: '', obj: {} } });
  }

  const deleteAviso = () => {
    showLoader(true);
    apiDeleteAviso(modal.obj.aviso_id)
      .then(response => {
        if (!response.message) {
          dispatch({
            type: "SET_DATA", payload: {
              ...data,
              rows: data.rows.filter(r => r.aviso_id !== modal.obj.aviso_id)
            }
          })
          NotificationManager.success("Se ha eliminado el aviso.", "¡Éxito!", 5000);
        } else {
          NotificationManager.error("Ocurrió un error al eliminar el aviso.", "¡Error!", 5000);
        }
      })
      .catch(() => {
        NotificationManager.error("Ocurrió un error al eliminar el aviso.", "¡Error!", 5000);
      })
      .finally(() => {
        showLoader(false);
        handleClose();
      })
  }

  const mapAvisosToViewModel = useCallback((avisos) => 
    avisos.map(aviso => ({
      id: aviso.aviso_id,
      titulo: aviso.titulo,
      opciones: <div className="btn-container">
                  <button onClick={() => handleShowModal(aviso)} className="btn btn-danger"><i className="fas fa-times" /> Eliminar</button>
                </div>
    }))
  , [])

  const handlePageClick = (newPage) => {
    dispatch({ type: 'SET_PAGE', payload: newPage });
    const location = {
      pathname: `${props.location.pathname}`,
      state: { page: newPage }
    }
    props.history.replace(location);
  }

  return <div>
    <div className="row mg-top">
      <div className="col-8">
        <h1><i className="fas fa-bell" /> Avisos</h1>
      </div>
      <div className="col-4 tar">
        <Link to="/avisos/nuevo" className="btn btn-success"><i className="fas  fa-plus" /> Nuevo Aviso</Link>
      </div>
    </div>

    <div className="card mg-top">
      <div className="card-body">
        <div className="table-responsive">
          <Table
            columns={["ID", "Título", "Opciones"]}
            data={mapAvisosToViewModel(data.rows)}
            tableClass=' mg-top table table-striped tac'
            configTable={{
              columnDefinition: {},
              columnStyle: { opciones: { textAlign: 'center' } }
            }}
          />
        </div>

        <div className="mg-top">
          <Pagination
            total={data.count}
            page={page}
            limit={10}
            pageClick={handlePageClick}
            showLimit={true}
          />
        </div>

      </div>
    </div>

    <ModalEliminarAviso
      show={modal.show}
      name={modal.name}
      handleClose={handleClose}
      confirm={deleteAviso}
    />
  </div>
}

export default withRouter(loaderConnect(Avisos));