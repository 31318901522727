import React from 'react';
import { apiGetCanchas } from '../../API/apiCanchas';

const Button = ({ onEditClick, text, className, handleActiveCancha }) => {
  return <div className="btn-group" role="group" style={{ marginRight: ".5em", boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.25)" }}>
    <button type="button" className="btn btn-secondary" onClick={onEditClick}><i title="EDITAR" className="fas fa-edit" /></button>
    <button type="button" className={className} onClick={handleActiveCancha}>{text}</button>
  </div>
}

const CanchasList = (props) => {
  const { setPropsSelectedCancha, update } = props;

  const [selectedCancha, setSelectedCancha] = React.useState({ id_area: 0 });
  const [canchasList, setCanchasList] = React.useState({ count: 0, rows: [] });

  React.useEffect(() => {
    apiGetCanchas()
      .then(response => {
        if (!response.message) {
          if (response.rows.length > 0) {
            setPropsSelectedCancha(response.rows[0]);
            setSelectedCancha(response.rows[0]);
          }
          setCanchasList(response);
        }
      })
  }, [setPropsSelectedCancha]);

  React.useEffect(() => {
    apiGetCanchas()
      .then(response => {
        if (!response.message) {
          setCanchasList(response);
        }
      })
  }, [update])

  const handleNewCancha = () => {
    props.history.push("/canchas/nueva");
  }

  const handleActiveCancha = (cancha) => {
    setSelectedCancha(cancha);
    setPropsSelectedCancha(cancha);
  }

  const GoEdit = (cancha) => {
    props.history.push("/canchas/editar/" + cancha.id_area);
  }

  return <div className="canchas">
    {
      canchasList.rows.map(cancha =>
        <Button
          key={cancha.id_area}
          //style={{ boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.25)" }}
          onEditClick={() => GoEdit(cancha)}
          handleActiveCancha={(cancha.id_area === selectedCancha.id_area) ? () => { } : () => handleActiveCancha(cancha)}
          text={cancha.nombre}
          className={(cancha.id_area === selectedCancha.id_area) ? "btn btn-primary" : "btn btn-light"}
        />
      )
    }
    <button className="btn btn-success" onClick={handleNewCancha}><i className="fas fa-plus" /> Nueva cancha</button>
  </div>

}

export default CanchasList;