import React from "react";
import loaderConnect from '../../Components/Loader/loaderConnect';
import ListManager from "../../Components/ListManager/listManager";
import { apiGetSuscriptions, apiUpdateSuscripcion } from '../../API/api';
import Filtros from './filtros';

const SusciptionsList = (props) => {

    const { showLoader, NotificationManager } = props;

    // const updateStatusSuscription=(item)=>{
    //     apiUpdateSuscripcion()
    //     .then()
    //     .catch()
    // }

    const mapData = (data, handleEdit, handleDetails, handleInactive) => {
        let datatable = [];

        datatable = data.map(elm => {
            return {
                codigo: elm.codigo ? elm.codigo : "N/A",
                nombre: elm.nombre,
                precio: "$" + elm.precio.toFixed(2),
                familiar: elm.plan_familiar ? "Si" : "No",
                mienbros: elm.numero_miembros_plan ? elm.numero_miembros_plan : "N/A",
                opciones: <div className="btn-container">
                    {!elm.protegida && <button onClick={() => handleInactive(elm)} className={"btn" + (elm.activo ? " btn-danger" : " btn-info")}><i className="fas fa-ban" /> {elm.activo ? "Dar de baja" : "Activar"}</button>}
                    <button onClick={() => handleEdit(elm)} className="btn btn-success"><i className="fas fa-edit" /> Editar</button>
                </div>
            }
        })

        return datatable;
    }

    const update = (elm, inative) => {
        showLoader(true);
        apiUpdateSuscripcion(elm.id_suscripcion, { activo: !elm.activo })
            .then(response => {
                if (!response.message) {
                    inative(response);
                    NotificationManager.success("Datos actualizados correctamente.", "¡Éxito!", 5000);
                    return response;
                } else {
                    NotificationManager.error("Ocurrió un error al guardar los datos.", "¡Error!", 5000);
                    return false;
                }
            })
            .catch(() => {
                NotificationManager.error("Ocurrió un error al guardar los datos.", "¡Error!", 5000);
                return false;
            })
            .finally(() => {
                showLoader(false);
            });
    }

    return <ListManager
        title="Suscripciones"
        icon="fas fa-id-card"
        link="/suscripciones/nueva"
        btnNewText="Nueva suscripción"
        tableOptions={{
            columns: ["Código", { name: "Nombre", className: "tal" }, "Precio", "Familiar", "No. miembros", { name: "Opciones", className: "tar" }],
            pagination: true,
            configTable: {
                columnDefinition: {},
                columnStyle: { nombre: { textAlign: 'left' }, opciones: { textAlign: 'right' } }
            }
        }}
        mapData={mapData}
        Filters={Filtros}
        location={{ state: null }}
        GetData={apiGetSuscriptions}
        showLoader={props.showLoader}
        keyLabel="id_suscripcion"
        update={update}
        {...props}
    />
}

export default loaderConnect(SusciptionsList)