import React from 'react';
import { Modal } from 'react-bootstrap';
import moment from "moment";

const ModalCancel = (props) => {
    const { show, handleClose, confirm, message } = props;

    const handleCloseModal = () => {
        handleClose();
    }

    const handleConfirm = () => {
        confirm();
    }

    return (
        <Modal show={show} onHide={handleCloseModal} centered
            dialogClassName="modal-20w">
            <Modal.Header closeButton>
                <Modal.Title>Confirmar cancelación</Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <div className="tac" style={{ fontSize: "20px" }}>
                    {message}
                    {/* <label> ¿Quieres cancelar la reservación a <b>{name}</b> el <b>{formatDate()}</b>?</label> */}
                </div>

            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-secondary" onClick={handleCloseModal}>No, cerrar</button>
                <button className="btn btn-danger" onClick={handleConfirm}>Si, cancelar</button>
            </Modal.Footer>
        </Modal>
    );
}

ModalCancel.defaultProps = {
    show: false,
    name: '',
    date: moment(),
    confirm: () => { },
    handleClose: () => { },
}

export default ModalCancel;
