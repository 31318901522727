
import { baseUrl, GET, POST, PUT, makeUrlGET, CreateAuthRequest, DELETE } from "./constan"


//region Notificaciones
let urlAPI = baseUrl + "notificacion";
export const apiGetNotificaciones = async (obj) => {
  let url = `${urlAPI}?` + makeUrlGET(obj);
  const request = await CreateAuthRequest(GET, null, true);
  return fetch(url, request)
    .then(response => response.json())
}

export const apiNewNotificacion = async (obj) => {
  const request = await CreateAuthRequest(POST, obj, true);
  return fetch(urlAPI, request)
    .then(response => response.json())
}

export const apiDeleteNotification = async (id) => {
  let url = `${urlAPI}/${id}`;
  const request = await CreateAuthRequest(DELETE, null, true);
  return fetch(url, request)
    .then(response => response.json())
}
//endregion
