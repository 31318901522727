import React from 'react';
import { apiGetSingleCancha, apiNewCancha, apiUpdateCancha } from '../../API/apiCanchas';
import { withRouter } from 'react-router-dom';
import Switch from "react-switch";

const NewCancha = (props) => {
  const { location, showLoader, NotificationManager } = props;
  const isEditMode = location.pathname.includes("editar");
  const [cancha, setCancha] = React.useState({ puerta: null, nombre: '', descripcion: '', protegida: false });
  const [originalProtegida, setOriginalProtegida] = React.useState(false);

  React.useEffect(() => {
    if (isEditMode) {
      showLoader(true);
      apiGetSingleCancha(props.match.params.id)
        .then(response => {
          if (!response.message) {
            setCancha(response);
            setOriginalProtegida(response.protegida);
          }
        })
        .finally(() => {
          showLoader(false);
        })
    }
  }, [isEditMode, showLoader, props.match.params.id]);

  const handleChangeCanchaValue = (e) => {
    const { name, value } = e.target;
    setCancha({ ...cancha, [name]: value });
  }

  const goBack = () => {
    props.history.goBack();
  }

  const submitCancha = (e) => {
    e.preventDefault();
    if (e.target.checkValidity()) {
      showLoader(true);
      if (isEditMode) {
        const { activo, id_area, updatedAt, createdAt, ...sus } = cancha;
        apiUpdateCancha(id_area, sus)
          .then(response => {
            if (!response.message) {
              setCancha(response);
              NotificationManager.success('Datos de la cancha actualizados.', '¡Exito!', 5000);
            }
            else {
              NotificationManager.error('Ocurrió un error al guardar los datos.', '¡Error!', 5000);
            }
          })
          .catch(() => {
            NotificationManager.error('Ocurrió un error al guardar los datos.', '¡Error!', 5000);
          })
          .finally(() => {
            showLoader(false);
          })
      } else {
        const { protegida, ...obj } = cancha;
        apiNewCancha(obj)
          .then(response => {
            if (!response.message) {
              props.history.push("/canchas");
              NotificationManager.success('Cancha creada exitósamente.', '¡Exito!', 5000);
            }
            else {
              NotificationManager.error('Ocurrió un error al guardar los datos.', '¡Error!', 5000);
            }
          })
          .catch(() => {
            NotificationManager.error('Ocurrió un error al guardar los datos.', '¡Error!', 5000);
          })
          .finally(() => {
            showLoader(false);
          })
      }
    }
  }


  const changeSwitch = (name, value) => {
    setCancha({ ...cancha, [name]: value });
  }

  const handleInputNumberChange = (e) => {
    let value = cancha.puerta, use = /^\d*$/.test(e.target.value);
    setCancha({ ...cancha, puerta: use ? e.target.value : value });
  }

  return <div>
    <div className="mg-top">
      <h1><i className="fas fa-id-card" /> Canchas</h1>
    </div>
    <div className="card mg-top">
      <div className="card-body">
        <h3 onClick={goBack}><i className="fas fa-arrow-left" /> {isEditMode ? "Editar" : 'Nueva'} cancha</h3>

        <form onSubmit={submitCancha}>

          <div className="mg-top row">
            <div className="col-md-4 form-group">
              <label className="title">Puerta</label>
              <input type="text" pattern="[0-9]*" className="form-control" name="puerta" value={cancha.puerta} onChange={handleInputNumberChange} placeholder="Nombre" required />
            </div>
            <div className="col-md-4 form-group">
              <label className="title">Nombre</label>
              <input className="form-control" name="nombre" value={cancha.nombre} onChange={handleChangeCanchaValue} placeholder="Nombre" required />
            </div>
            <div className="col-md-4 form-group">
              {isEditMode && originalProtegida ?
                <React.Fragment>
                  <p style={{ marginBottom: ".2em" }}><label className="title">Protegida</label></p>
                  <label>Ésta cancha esta protegida</label>
                </React.Fragment>
                :
                <React.Fragment>
                  <p><label className="title">Protegida</label></p>
                  <Switch
                    checked={cancha.protegida}
                    onChange={(value) => changeSwitch('protegida', value)}
                    onColor="#1DC775"
                    offColor="#DD6E07"
                    onHandleColor="#E0E0E0"
                    handleDiameter={20}
                    // uncheckedIcon={true}
                    // checkedIcon={true}
                    className="react-switch"
                  />
                </React.Fragment>
              }
            </div>
            <div className="col-md-6 form-group">
              <label className="title">Descripción</label>
              <textarea rows={3} className="form-control" name="descripcion" value={cancha.descripcion} onChange={handleChangeCanchaValue} placeholder="Descripción" required />
            </div>


          </div>
          <div className="mg-top tac">
            <button type="submit" className="btn btn-success">Guardar cancha</button>
          </div>
        </form>
      </div>

    </div>
  </div>
}

export default withRouter(NewCancha);