import React from "react";
import { Link } from 'react-router-dom';
import loaderConnect from '../Loader/loaderConnect';
import Pagination from '../Pagination/pagination';
import Table from './table';


const reducer = (state, action) => {
  switch (action.type) {
    case "SET_FILTERS":
      return { ...state, filters: action.payload, page: 1 }
    case "SET_DATA":
      return { ...state, data: action.payload }
    case 'SET_PAGE':
      return { ...state, page: action.payload }
    case 'SET_LIMIT':
      return { ...state, limit: action.payload }
    default:
      return { ...state }
  }
}

const ListManager = (props) => {

  const initialState = {
    filters: {},
    data: { count: 0, rows: [] },
    limit: props.location.state ? props.location.state.limit : 10,
    page: props.location.state ? props.location.state.page : 1
  };

  const { keyLabel, title, icon, link, tableOptions, Filters, btnNewText, GetData, mapData, showLoader } = props;
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { data, page, filters, limit } = state;

  React.useEffect(() => {
    showLoader(true);
    GetData({ page, limit, ...filters })
      .then(response => {
        if (response) {
          if (!response.message) {
            dispatch({ type: "SET_DATA", payload: response })
          }
        }
      })
      .finally(r => {
        showLoader(false);
      })
  }, [page, GetData, filters, showLoader, limit]);

  const handleEdit = (item) => {
    const location = {
      pathname: `${props.location.pathname}`,
      state: { page, limit }
    }
    props.history.replace(location);
    props.history.push(`${props.location.pathname}/editar/${item[keyLabel]}`);
  }

  const handleDetail = (item) => {
    const location = {
      pathname: `${props.location.pathname}`,
      state: { page, limit }
    }
    props.history.replace(location);
    props.history.push(`${props.location.pathname}/detalles/${item[keyLabel]}`);
  }

  const inative = (elm) => {
    dispatch({
      type: "SET_DATA", payload: {
        ...data,
        rows: data.rows.map(r => r[keyLabel] === elm[keyLabel] ? elm : r)
      }
    })
  }

  React.useEffect(() => {
    inative(props.elmToDelete)
  }, [props.elmToDelete])

  const handleActiveInactive = (elm) => {
    props.update(elm, inative);

  }

  const mapDataLM = (data) => {
    return mapData(data, handleEdit, handleDetail, handleActiveInactive);
  }


  const handlePageClick = (newPage) => {
    if (newPage !== page) {
      dispatch({ type: 'SET_PAGE', payload: newPage });
      // const location = {
      //     pathname: `${props.location.pathname}`,
      //     state: { page: newPage }
      // }
      // props.history.replace(location);
    }
  };

  const handleLimitChange = (newLimit) => {
    if (newLimit !== limit) {
      dispatch({ type: 'SET_LIMIT', payload: newLimit });
    }
  };

  const onFiltersChange = React.useCallback((NewFilters) => {
    dispatch({ type: "SET_FILTERS", payload: NewFilters });
    // dispatch({ type: "SET_PAGE", payload: 1 });
  }, [])

  return <div>
    <div className="row mg-top">
      <div className="col-8">
        <h1><i className={icon} /> {title}</h1>
      </div>
      <div className="col-4 tar">
        <Link to={link} className="btn btn-success"><i className="fas  fa-plus" /> {btnNewText}</Link>
      </div>
    </div>

    <div className="card mg-top">
      <div className="card-body">

        {Filters && <Filters onFiltersChange={onFiltersChange} filters={filters} />}

        <div className="table-responsive">
          <Table
            columns={tableOptions.columns}
            data={mapDataLM(data.rows)}
            tableClass=' mg-top table table-striped tac'
            configTable={tableOptions.configTable}
          />
        </div>

        {tableOptions.pagination &&
          <div className="mg-top">
            <Pagination
              total={data.count}
              page={page}
              limit={limit}
              pageClick={handlePageClick}
              showLimit={true}
              limitChange={handleLimitChange}
            />
          </div>
        }
      </div>
    </div>
  </div>
}

export default loaderConnect(ListManager);