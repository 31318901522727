import React from "react";
import styles from "./dayofweek.module.css";

const Dayofweek = ({
  name,
  label,
  customClass,
  handleInputChange,
  value,
  valid,
  touched,
  errors,
}) => {
  const handleOnChange = (day) => {
    handleInputChange({
      target: {
        name,
        value: day,
      },
      type: "change",
    });
  };

  return (
    <div className={`wafo-wrapper ${customClass}`}>
      <div className="form-group wafo-input">
        {label && <label htmlFor="picker-datetime">{label}</label>}
        <div className={styles["wrapper"]}>
          <div className="btn-group" role="group" aria-label="Basic example">
            <button
              type="button"
              className={`btn ${value === 1 ? "btn-secondary" : "btn-light"}`}
              onClick={() => handleOnChange(1)}
            >
              Lunes
            </button>
            <button
              type="button"
              className={`btn ${value === 2 ? "btn-secondary" : "btn-light"}`}
              onClick={() => handleOnChange(2)}
            >
              Martes
            </button>
            <button
              type="button"
              className={`btn ${value === 3 ? "btn-secondary" : "btn-light"}`}
              onClick={() => handleOnChange(3)}
            >
              Miércoles
            </button>
            <button
              type="button"
              className={`btn ${value === 4 ? "btn-secondary" : "btn-light"}`}
              onClick={() => handleOnChange(4)}
            >
              Jueves
            </button>
            <button
              type="button"
              className={`btn ${value === 5 ? "btn-secondary" : "btn-light"}`}
              onClick={() => handleOnChange(5)}
            >
              Viernes
            </button>
            <button
              type="button"
              className={`btn ${value === 6 ? "btn-secondary" : "btn-light"}`}
              onClick={() => handleOnChange(6)}
            >
              Sábado
            </button>
            <button
              type="button"
              className={`btn ${value === 7 ? "btn-secondary" : "btn-light"}`}
              onClick={() => handleOnChange(7)}
            >
              Domingo
            </button>
          </div>
        </div>
        {!valid && touched && (
          <ul className="errors">
            {errors.map((error) => (
              <li key={error.error}>{error.message}</li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default Dayofweek;
