import React, { useState } from 'react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import loaderConnect from '../../Components/Loader/loaderConnect';
// import { Link } from 'react-router-dom';
import { apiSignIn } from '../../API/api';
import logo from '../../Img/logo.png';
import Loader from '../../Components/Loader/';
import '../../Style/login.scss';

const Login = (props) => {
    const { showLoader } = props;
    const [formValues, changeValue] = useState({ email: '', password: '' });

    const [active, changeActive] = useState(false);

    function ChangeValues(e) {
        const { name, value } = e.target;
        changeValue({ ...formValues, [name]: value });
    };

    function TogglePassword(e) {
        changeActive(!active);
    };

    const Submit = (e) => {
        e.preventDefault();
        if (e.target.checkValidity()) {
            showLoader(true);
            // setTimeout(() => {

            //     NotificationManager.error('usuario y/o contraseña incorrectos.', 'Error de validación', 4000);
            //     showLoader(false);
            // }, 1000);
            apiSignIn({ password: formValues.password, correo: formValues.email })
                .then(response => {
                    showLoader(false);
                    if (response.message) {
                        NotificationManager.error('usuario y/o contraseña incorrectos.', 'Error de validación', 5000);
                    }
                    else {
                        localStorage.setItem('token', response.token);
                        NotificationManager.success('', '¡Bienvenido!', 700);
                        setTimeout(() => {
                            props.history.push('/residencias');
                        }, 700);
                    }
                })
                .catch(error => {
                    showLoader(false);
                    NotificationManager.error('Inténtelo más tarde.', 'Ocurrió un error', 4000)
                })
        }
        else {
            NotificationManager.error('usuario y/o contraseña incorrectos.', 'Error de validación', 4000);
        }
    };

    return (
        <div className="login">
            <div className="contenedor">
                <div className="card" >
                    <div className="logo tac">
                        <img style={{ width: 'auto', height: '90%', marginTop: '5px' }} src={logo} alt="Áreas comunes" />
                    </div>
                    <div className="card-body">

                        {/* <h3 className="my-3"> Login:</h3> */}



                        <div className="tac">
                            <label className="welcome">El club de la raqueta</label>
                        </div>


                        <form onSubmit={Submit}>
                            <div className="form-group tal">
                                <label className="label-title">Usuario</label>
                                <input
                                    name="email"
                                    id='email'
                                    value={formValues.email}
                                    type="email"
                                    onChange={ChangeValues}
                                    className="form-control"
                                    required
                                    placeholder="Escribe tu nombre de usuario"
                                />
                            </div>

                            <div className="form-group tal">
                                <label className="label-title">Contraseña</label>



                                <div className="input-group w-100">
                                    <input className="form-control"
                                        name="password"
                                        id='password'
                                        value={formValues.password}
                                        type={active ? "text" : "password"}
                                        onChange={ChangeValues}
                                        minLength="6"
                                        required
                                        placeholder="Escribe tu contraseña" />
                                    <div className="input-group-append">
                                        <button id="btn-sp" className="btn  btn-show-password" onClick={TogglePassword} type="button">
                                            {
                                                active ?
                                                    <span className="far fa-eye-slash"></span>
                                                    :
                                                    <span className="far fa-eye"></span>
                                            }
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="div-submit">
                                <button type="submit" className="btn btn-primary form-control">Iniciar sesión</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <NotificationContainer />
            <Loader />
        </div>
    );
};

export default loaderConnect(Login);