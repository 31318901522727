import React from 'react';
import './paginacion.scss';

const GetPagination = ({ total, page, limit, pageClick, limitOption, showLimit, limitChange }) => {
  let pages = Math.ceil(total / limit);

  const getPages = () => {
    let items = [];
    for (let i = (page - 3); i <= (page + 3); i++) {
      if (i > 0 && i <= pages) {
        items.push(i);
      }
    }
    return items.map((elm, index) => {
      if (elm === page) {
        return <li className="page-item active" key={elm} >
          <button className="page-link">
            {elm} <span className="sr-only">(current)</span>
          </button>
        </li>
      }
      else {
        return <li className="page-item" key={elm} onClick={() => clickOnPage(elm)}>
          <button className="page-link">
            {elm}
          </button>
        </li>
      }
    });

  }

  const clickOnPage = (num) => {
    if (num <= pages && num > 0) {
      pageClick(num);
    }
  }

  const changeLimit = (e) => {
    limitChange(parseInt(e.target.value));
  }

  return (
    <nav className="pagination nav justify-content-end">
      {
        showLimit &&
        <li className="mr-auto col-md-3">
          {`Mostrando `}

          <select className="limit" value={limit} onChange={changeLimit}>
            {limitOption.map(opt =>
              <option key={opt} value={opt}>{opt}</option>
            )}
          </select>
          {' resultados'}

        </li>}


      <li className="page-item" disabled={page === 1} onClick={() => clickOnPage(page - 1)}>
        <button className="page-link" aria-label="Previous">
          <i className="fas fa-angle-left"></i>
        </button>
      </li>


      {getPages()}


      <li className="page-item" disabled={page === pages} onClick={() => clickOnPage(page + 1)}>
        <button className="page-link" >
          <i className="fas fa-angle-right"></i>
        </button>
      </li>

    </nav>
  );
};

GetPagination.defaultProps = {
  total: 0,
  page: 1,
  limit: 10,
  pageClick: () => { },
  limitOption: [10, 25, 50, 100],
  showLimit: false,
  limitChange: () => { }
};

export default GetPagination;