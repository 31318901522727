import React, { useState } from "react";
import { apiNewAviso } from '../../API/apiAvisos';

const NewAviso = (props) => {
  const { showLoader, NotificationManager } = props;
  const [form, setForm] = useState({});

  const handleChangeFormsValue = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  }
  
  const handleFileChange = async ({ currentTarget: input }) => {
    setForm({ 
      ...form,
      [input.name]: input.files[0]
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (e.target.checkValidity()) {
      try {
        showLoader(true);
        const body = getBody(form);
  
        const response = await apiNewAviso(body)
        if (!response.message) {
          NotificationManager.success("Se ha creado el aviso correctamente.", "¡Éxito!", 1000);
          props.history.push("/avisos");
        }
      } catch (error) {
        showLoader(true);
        NotificationManager.error("No se ha podido crear el aviso.", "¡Error!", 5000);
      }
    }
  }
  const getBody = (values) => {
    const body = new FormData();
    for (const key of Object.keys(values)) {
       if (values[key]) {
          body.append(key, values[key]);
       }
    }
    return body;
 };

  return <div>
    <div className="">
    <h1><i className="fas fa-bell" /> Avisos
            </h1>
    </div>
    <div className="mg-top card">
      <div className="card-body">
        {/* <h3>
          <i className="fas fa-arrow-left" onClick={props.history.goBack()} />  
          Nuevo aviso
        </h3> */}

        <form onSubmit={handleSubmit}>
          <div className="mg-top row">

            <div className="col-md-6 form-group">
              <label className="title">Título</label>
              <input className="form-control" name="titulo" value={form.titulo} onChange={handleChangeFormsValue} placeholder="Título de notificación" required />
            </div>

            <div className="col-md-6 form-group">
              <label className="title">Descripción</label>
              <textarea className="form-control" placeholder="Escriba su mensaje aquí" name="descripcion" value={form.descripcion} onChange={handleChangeFormsValue} required />
            </div>

            <div className="col-md-6 form-group">
              <label for="header_image" class="form-label">Imagen de cabecera</label>
              <input class="form-control" type="file" id="header_image" accept=".png,.jpeg,.jpg,.gif" name="header_image" onChange={handleFileChange} />
            </div>

            <div className="col-md-6 form-group">
              <label for="body_image" class="form-label">Imagen en el mensaje</label>
              <input class="form-control" type="file" id="body_image" accept=".png,.jpeg,.jpg,.gif" name="body_image" onChange={handleFileChange} />
            </div>

          </div>

          <div className="mg-top tac">
            <button className="btn btn-success" type="submit">Guardar aviso</button>
          </div>
        </form>
      </div>
    </div>

  </div>
}

export default NewAviso;