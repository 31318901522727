import React, { useEffect, useState } from 'react';
import styles from './monthsOfYear.module.css';
import moment from 'moment';
import ModalConfirm from '../../General/modalConfirm';

const months = ['ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC'];
const defaultModal = { show: false, props: {} };

const MonthsOfYear = ({ currentEnd, updateSuscription }) => {
  const [year, setYear] = useState(() => {
    const date = new Date();
    return date.getFullYear();
  });

  const [validUntil, setValidUntil] = useState({
    year: 0,
    month: 0,
  });

  const [modal, setModal] = useState(defaultModal);

  const changeYear = action => {
    if (action === 'minus') {
      setYear(prev => --prev);
    } else if (action === 'plus') {
      setYear(prev => ++prev);
    }
  };

  useEffect(() => {
    const end = moment(currentEnd);
    const endYear = end.year();
    let endMonth = end.month();

    // Como la fecha de fin siempre es el inicio del siguiente mes.
    // Se toma como limite valido el mes anterior a ese.

    setYear(endYear);
    setValidUntil({
      year: endYear,
      month: endMonth,
    });
  }, [currentEnd]);

  const handleUpdate = () => {
    const {
      props: { month },
    } = modal;
    updateSuscription(year, month);
    setModal(defaultModal);
  };

  return (
    <React.Fragment>
      <div>
        <div className="input-group mb-3">
          <div className="input-group-prepend">
            <button className="btn btn-outline-secondary" type="button" onClick={() => changeYear('minus')}>
              <i className="fas fa-chevron-left" />
            </button>
          </div>
          <input
            type="text"
            className="form-control text-center"
            aria-label="Example text with button addon"
            aria-describedby="button-addon1"
            disabled
            value={year}
          />
          <div className="input-group-append">
            <button className="btn btn-outline-secondary" type="button" onClick={() => changeYear('plus')}>
              <i className="fas fa-chevron-right" />
            </button>
          </div>
        </div>
        <div className={styles.grid}>
          {months.map((month, index) => (
            <button
              key={index}
              type="button"
              className={`btn ${
                year < validUntil.year || (year === validUntil.year && index + 1 <= validUntil.month)
                  ? 'btn-primary'
                  : 'btn-secondary'
              } `}
              onClick={() =>
                setModal({
                  show: true,
                  props: { month: index + 1 },
                })
              }
              disabled={year < validUntil.year || (year === validUntil.year && index + 1 <= validUntil.month)}
            >
              {month}
            </button>
          ))}
        </div>
      </div>
      <ModalConfirm
        show={modal.show}
        message="¿Desea actualizar la fecha limite de la suscripción?"
        handleClose={() => setModal(defaultModal)}
        handleConfirm={() => handleUpdate()}
      />
    </React.Fragment>
  );
};

export default MonthsOfYear;
