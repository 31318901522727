import React from 'react';
import { Route, Switch, Redirect, BrowserRouter } from 'react-router-dom';
import PrivateRoute from './Routes/privateRoute';
import Login from './Views/Login/login';
import moment from 'moment';
// import Contenedor from './Views/Container/index';
import { Provider } from 'react-redux';
import store from './Reducers/store';
import './App.css';
import './Style/dateTime.scss';
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.css";
import 'react-notifications/lib/notifications.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import '@wafo/forms/dist/index.css'; // Custom forms styling

moment.updateLocale('es', {
    monthsShort: [
        "Ene", "Feb", "Mar", "Abr", "May", "Jun",
        "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"
    ],
    months: [
        "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio",
        "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
    ],
    weekdays: 'Domingo_Lunes_Martes_Miércoles_Jueves_Viernes_Sábado'.split('_'),
    weekdaysShort: 'Dom_Lun_Mar_Mié_Jue_Vie_Sáb.'.split('_'),
    weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_'),
    weekdaysParseExact: true,
});

function App() {
    return (
        <Provider store={store}>
            <div className="App">
                <BrowserRouter>
                    <Switch>
                        <Route path="/login" component={Login} />
                        <PrivateRoute></PrivateRoute>
                        <Redirect from='/' to='/login' />
                    </Switch>
                </BrowserRouter>
            </div>
        </Provider>
    );
}

export default App;

