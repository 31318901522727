import React from 'react';
import { Modal } from 'react-bootstrap';

const ModalEliminarAviso = (props) => {
  const { show, handleClose, confirm, name } = props;

  const handleCloseModal = () => {
    handleClose();
  }

  const handleConfirm = () => {
    confirm();
  }

  return (
    <Modal show={show} onHide={handleCloseModal} centered
      dialogClassName="modal-20w">
      <Modal.Header closeButton>
        <Modal.Title>Confirmar eliminar aviso</Modal.Title>
      </Modal.Header>
      <Modal.Body >
        <div className="tac" style={{ fontSize: "20px" }}>
          <label> ¿Quieres eliminar el aviso <b>{name}</b>?</label>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary" onClick={handleCloseModal}>No, cerrar</button>
        <button className="btn btn-danger" onClick={handleConfirm}>Si, eliminar</button>
      </Modal.Footer>
    </Modal>
  );
}

ModalEliminarAviso.defaultProps = {
  show: false,
  name: '',
  confirm: () => { },
  handleClose: () => { },
}

export default ModalEliminarAviso;