import React from 'react';
import { withRouter, useHistory, Link } from 'react-router-dom';
import CalendarComponent from './calendar';
import CanchasList from './canchasList';
import { apiGetReservaciones, apiCancelReservaciones } from '../../API/apiCanchas';
import moment from 'moment';
import ModalCancel from './modalCancel';
import { tipoJuego } from '../../API/constan';
import DetailArea from './detailArea';
import PropTypes from 'prop-types';
import styles from './areas.module.css';
import { apiCancelClase } from '../../API/api';

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_SELECTED_AREA":
      return { ...state, selectedCancha: action.payload }
    case "SET_DATES":
      return { ...state, dates: action.payload }
    case "SET_ALL_RESERVATIONS":
      return { ...state, allReservations: action.payload }
    case "SET_MODAL":
      return { ...state, modal: action.payload }
    case "SET_UPDATE_CANCHAS_LIST":
      return { ...state, updateCanchasList: action.payload }
    default:
      return { ...state }
  }
}

const today = moment();
const initialState = {
  selectedCancha: { id_area: 0, nombre: '', descripcion: '', activo: false },
  dates: {
    fecha_inicio: moment().startOf('month').toISOString(),
    fecha_fin: moment().endOf('month').toISOString()
  },
  allReservations: [],
  modal: { show: false, date: today, name: '', id_reservacion: 0, clase: 0, relacionadas: false },
  updateCanchasList: 1
};

const Areas = (props) => {
  const { showLoader, NotificationManager } = props;
  const history = useHistory();

  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { selectedCancha, dates, allReservations, modal, updateCanchasList } = state;

  React.useEffect(() => {
    const mapAllReservation = (reservations) => {
      let allResers = [], today = moment();
      if (reservations.hasOwnProperty("reservaciones_actuales")) {
        for (let i = 0; i < reservations.reservaciones_actuales.length; i++) {
          const dia = reservations.reservaciones_actuales[i];
          for (let j = 0; j < dia.reservaciones.length; j++) {
            const reservacion = dia.reservaciones[j];
            let day = moment(reservacion.fecha_hora);
            let start = moment(reservacion.fecha_hora);
            const obj = {
              title: reservacion.residente.codigo_residente + " - " + reservacion.residente.nombres + ' ' + reservacion.residente.apellidos,
              start: new Date(start.toISOString()),
              end: new Date(start.add(1, "hours").toISOString()),
              day: day.format("dddd DD [de] MMM, ") + moment(reservacion.fecha_hora).format("h A"),
              date: moment(reservacion.fecha_hora),
              id_reservacion_residencia: reservacion.id_reservacion_residencia,
              allDay: false,
              future: moment(day).isAfter(today),
              tipoJuego: tipoJuego.find(juego => juego.id === reservacion.tipo_juego)?.name,
              invitados: reservacion.invitados,
              clase: reservacion.codigo_clase,
            };
            allResers.push(obj);
          }
        }
      }
      dispatch({ type: "SET_ALL_RESERVATIONS", payload: allResers });
    }

    if (selectedCancha.id_area > 0) {
      showLoader(true);
      apiGetReservaciones(selectedCancha.id_area, dates)
        .then(response => {
          if (!response.message) {
            dispatch({ type: "SET_ALL_RESERVATIONS", payload: [] });
            mapAllReservation(response);
          }
          else {
            dispatch({ type: "SET_ALL_RESERVATIONS", payload: [] });
          }
        })
        .finally(() => {
          showLoader(false);
        })
    }
  }, [selectedCancha.id_area, dates, showLoader])

  const setSelectedCancha = React.useCallback((value) => {
    dispatch({ type: "SET_SELECTED_AREA", payload: value })
  }, []);

  const ChangeDates = React.useCallback(({ fecha_inicio, fecha_fin }) => {
    dispatch({
      type: "SET_DATES",
      payload: {
        fecha_inicio: fecha_inicio.toISOString(),
        fecha_fin: fecha_fin.toISOString()
      }
    });
  }, [])

  const handleShowModal = (elm) => {
    dispatch({
      type: "SET_MODAL", payload: {
        show: true,
        message: <label>¿Quieres cancelar la {elm.clase ? "clase" : "reservación"} {elm.relacionadas ? "y todas las relacionadas" : ""} a <b>{elm.title}</b> {elm.relacionadas ? "a partir de" : ""} el <b>{moment(elm.date).format("DD/MM/YYYY hh:mm a")}</b>?</label>,
        id_reservacion: elm.id_reservacion_residencia,
        clase: elm.clase,
        relacionadas: elm.relacionadas
      }
    });
  }

  const handleClose = () => {
    dispatch({ type: "SET_MODAL", payload: { show: false, date: today, name: '', id_reservacion: 0, clase: 0, relacionadas: false } });
  }

  const handleConfirm = () => {
    if (modal.clase) {
      showLoader(true);
      apiCancelClase({
        reservaciones: [modal.id_reservacion],
        clases_relacionadas: modal.relacionadas
      })
        .then(response => {
          if (!response.message) {
            NotificationManager.success('Se ha cancelado la reservacion', '¡Exito!', 5000);
          } else {
            NotificationManager.error(response.message, '¡Error!', 5000);
          }
        })
        .catch(() => {
          NotificationManager.error('No se ha podido cancelar la reservacion', '¡Error!', 5000);
        })
        .finally(() => {
          showLoader(false);
          dispatch({ type: "SET_MODAL", payload: { show: false, date: today, name: '', id_reservacion: 0 } });
          updateCanchas();
        })
    } else {
      showLoader(true);
      apiCancelReservaciones(modal.id_reservacion)
        .then(response => {
          if (!response.message) {
            dispatch({
              type: "SET_ALL_RESERVATIONS",
              payload: allReservations.filter(r => r.id_reservacion_residencia !== modal.id_reservacion)
            })
            NotificationManager.success('Se ha cancelado la reservacion', '¡Exito!', 5000);
          }
          else {
            NotificationManager.error('No se ha podido cancelar la reservacion', '¡Error!', 5000);
          }
        })
        .catch(() => {
          NotificationManager.error('No se ha podido cancelar la reservacion', '¡Error!', 5000);
        })
        .finally(() => {
          showLoader(false);
          dispatch({ type: "SET_MODAL", payload: { show: false, date: today, name: '', id_reservacion: 0 } });
          updateCanchas();
        })
    }

  }

  const updateCanchas = (cancha) => {
    dispatch({ type: "SET_UPDATE_CANCHAS_LIST", payload: Math.random() })
  }

  const orderedReservations = React.useMemo(() => {
    if (!Array.isArray(allReservations)) return [];
    const today = new Date();
    const ordered = allReservations.sort((a,b) => a.start.getTime() - b.start.getTime());
    const vigentes = ordered.filter(x => x.start > today);
    const caducas = ordered.filter(x => x.start < today);
    return [...vigentes, ...caducas];
  }, [allReservations]);

  return <div>
    <div className="mg-top">
      <h2><i className="fas fa-home" /> Canchas</h2>
      <div className={`mg-top ${styles['buttons']}`}>
        <CanchasList update={updateCanchasList} history={history} setPropsSelectedCancha={setSelectedCancha} />
        <Link to="/reservaciones/nueva" className="btn btn-primary"><i className="fas fa-calendar-check" /> Nueva reservación</Link>
      </div>
      <div className="mg-top" style={{ padding: '1em' }}>
        <div className="row">
          <div className="col-md-3 card" >
            <div className="agendados-list">
              <DetailArea selectedCancha={selectedCancha}
                showLoader={showLoader} NotificationManager={NotificationManager}
                updateCanchas={updateCanchas} />
              <hr />
              <h6>Lista de reservaciones ({allReservations.length})</h6>
              <div className="cards-container tac">
                {
                  orderedReservations.map(r =>
                    <div key={r.start} className="card mg-top " >
                      <div className={"card-body card-list " + (!r.future ? "disabled" : '')}>
                        <label className="title mg-0">{r.title}</label>
                        <label className="mg-0" style={{ display: "block" }}>{r.day}</label>
                        <label style={{ display: "block" }}>Tipo juego: {r.clase ? "Clase fija" : r.tipoJuego}</label>
                        {r.invitados.length > 0 &&
                          <div>
                            <label style={{ display: "block", marginBottom: "0" }}><b>Invitados:</b></label>
                            {r.invitados.map(i =>
                              <label key={i.invitado.codigo_residente} style={{ display: "block", marginBottom: "0" }}>{i.invitado.codigo_residente + ' - ' + i.invitado.nombres + " " + i.invitado.apellidos}</label>
                            )}
                          </div>
                        }
                        {r.future && r.clase && (
                          <div className="mg-top">
                            <button className="btn btn-danger" onClick={() => handleShowModal({...r, relacionadas: false})} style={{ marginRight: '.25rem' }}>
                              <i className="fas fa-ban" /> Clase
                            </button>
                            <button className="btn btn-danger" onClick={() => handleShowModal({...r, relacionadas: true})}><i className="fas fa-ban" /> Todas</button>
                          </div>
                        )}
                        {r.future && !r.clase && (
                          <div className="mg-top">
                            <button className="btn btn-danger" onClick={() => handleShowModal(r)}><i className="fas fa-ban" /> Cancelar</button>
                          </div>
                        )}
                      </div>
                    </div>
                  )
                }
                {
                  orderedReservations.length === 0 &&
                  <div>
                    <label>No hay reservaciones próximas.</label>
                  </div>
                }
              </div>

            </div>
          </div>
          <div className="col card" style={{ marginLeft: '1em' }}>
            <CalendarComponent
              events={allReservations}
              ChangeDates={ChangeDates}
            />
          </div>
        </div>
      </div>
    </div>
    <ModalCancel
      show={modal.show}
      message={modal.message}
      handleClose={handleClose}
      confirm={handleConfirm}
    />
  </div>
}

Areas.propTypes = {
  showLoader: PropTypes.func,
  NotificationManager:PropTypes.object
}

export default withRouter(Areas);