import React from "react";
import { apiGetSuscriptions } from '../../API/api';
//import Select from '../../Components/General/select';
import useDebounce from '../../Components/Hooks/useDebounce';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

const Filtros = (props) => {
    const { onFiltersChange, filters } = props;

    const [filtersValues, setFiltersValues] = React.useState({
        search: '',
        id_suscripcion: 0,
        activo: null
    });
    const [typeahead, setTypeahead] = React.useState({ isLoading: false, options: [] });
    const { search, id_suscripcion, activo } = filtersValues;
    const [firstTime, setFirstime] = React.useState(true);

    const debounce = useDebounce(search, 300);

    React.useEffect(() => {
        if (!firstTime) {
            onFiltersChange({ search: debounce, id_suscripcion, activo });
        } else {
            setFirstime(false);
        }
    }, [debounce]);

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        let val = value;
        if (name === "activo" && value === "Todos") {
            val = null;
            setFiltersValues({ ...filtersValues, activo: null });
        }
        else {
            if (name === "id_suscripcion" && parseInt(value, 10) === 0) {
                val = null;
                setFiltersValues({ ...filtersValues, id_suscripcion: null });
            } else {

                setFiltersValues({ ...filtersValues, [name]: val });
            }
        }

        if (name !== "search") {
            onFiltersChange({ ...filtersValues, [name]: val })
        }
    }

    const getSuscriptions = (search) => {
        setTypeahead({ isLoading: true });
        //        setOptions([]);
        apiGetSuscriptions({ search })
            .then(response => {
                setTypeahead({ isLoading: false, options: response.rows });
            })
            .catch(console.error)
    }

    return <div className="row">
        <div className="col-md-4 col-12">
            <input name="search" className="form-control" value={filtersValues.search} onChange={handleFilterChange} placeholder="Buscar por código, nombre, celular" />
        </div>
        <div className="col-md-4 col-12">
            <AsyncTypeahead
                id="AsyncTypeaheadChofer"
                labelKey='nombre'
                name="id_suscripcion"
                multiple={false}
                minLength={3}
                onChange={(value) => {
                    let obj = { target: { name: "id_suscripcion", value: 0 } }
                    if (value.length > 0) {
                        obj.target.value = value[0].id_suscripcion;
                    }
                    handleFilterChange(obj)
                }}

                isLoading={typeahead.isLoading}
                filterBy={['nombre', 'precio', 'id_suscripcion']}
                selectHintOnEnter={true}
                clearButton={true}
                searchText="Buscando..."
                placeholder="Filtrar por suscripción"
                options={typeahead.options}
                onSearch={(query) => getSuscriptions(query)}
                // defaultSelected={props.editElement ?
                //     (props.editElement.hasOwnProperty("driver") ?
                //         [props.editElement.driver] :
                //         []) :
                //     []}
                renderMenuItemChildren={(option, props) => (
                    <div key={option.id_suscripcion}>
                        {option.nombre}
                    </div>)
                }
            />
        </div>
        <div className="col-md-4 col-12">
            <select name="activo" value={activo} onChange={handleFilterChange} className="form-control">
                <option value={null}>Todos</option>
                <option value={1}>Activo</option>
                <option value={0}>Inactivos</option>
            </select>
        </div>
    </div>
}


export default Filtros;