import React from "react";
import useDebounce from '../../Components/Hooks/useDebounce';

const Filtros = (props) => {
    const { onFiltersChange } = props

    const [filtersValues, setFiltersValues] = React.useState({ search: '' });
    const debounce = useDebounce(filtersValues.search, 300);

    React.useEffect(() => {
        onFiltersChange({ search: debounce });
    }, [debounce]);

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFiltersValues({ ...filtersValues, [name]: value });
    }

    return <div className="row">
        <div className="col-md-4 col-12">
            <input name="search" className="form-control" value={filtersValues.search} onChange={handleFilterChange} placeholder="Buscar suscripción" />
        </div>
    </div>
}


export default Filtros;