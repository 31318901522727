
import { baseUrl, GET, POST, PUT, makeUrlGET, CreateAuthRequest, DELETE } from "./constan"


//region Notificaciones
let urlAPI = baseUrl + "avisos";
export const apiGetAvisos = async (obj) => {
  let url = `${urlAPI}?` + makeUrlGET(obj);
  const request = await CreateAuthRequest(GET, null, true);
  return fetch(url, request)
    .then(response => response.json())
}

export const apiNewAviso = async (obj) => {
  const request = await CreateAuthRequest(POST, obj, false);
  return fetch(urlAPI, request)
    .then(response => response.json())
}

export const apiDeleteAviso = async (id) => {
  let url = `${urlAPI}/${id}`;
  const request = await CreateAuthRequest(DELETE, null, true);
  return fetch(url, request)
    .then(response => response.json())
}
//endregion
