import React from 'react';
import { withRouter } from 'react-router-dom';
import SideBar from './sidebar';
import BoxContainer from './boxContainer';
import Loader from '../../Components/Loader/index';
import loaderConnect from '../../Components/Loader/loaderConnect';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import '../../Style/sidebar.scss';

const Container = (props) => {
  const generalProps = { ...props, NotificationManager }

  return (
    <div id="wrapper" className="toggled" >
      <Loader />
      <NotificationContainer />
      <div id="page-content-wrapper" className="container-fluid" style={{ marginBottom: "2em" }}>
        <SideBar />
        <div className="page-container">
          <BoxContainer  {...generalProps} />
        </div>
      </div>
    </div>
  );
};

export default withRouter(loaderConnect(Container));