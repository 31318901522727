import React from 'react';
import PropTypes from 'prop-types';

export const Table = ({ columns, data, configTable, tableClass, zeroRecord }) => {
    function configHeaders() {
        return columns.map((column, index) => {
            if (typeof column === "string") {
                return <th key={index}>{column}</th>
            }
            else {
                return <th key={index} className={column.className}>{column.name}</th>
            }
        });
    }

    function configRows() {
        const { columnDefinition, columnStyle } = configTable;

        const dataReady = data.map(row => (
            Object.keys(row).map((key) => {
                // Checking if there is a config function for this column.
                if (Object.prototype.hasOwnProperty.call(columnDefinition, key)) {
                    // return columnDefinition[key](row); If you want the entire object.
                    return {
                        value: columnDefinition[key](row[key]),
                        style: columnStyle[key],
                    };
                }
                return { value: row[key], style: columnStyle[key] };
            })
        ));

        return dataReady.map((obj, index) => <TableRow key={index} data={obj} />);
    }

    return (
        <table className={tableClass}>
            <thead>
                <tr>
                    {configHeaders()}
                </tr>
            </thead>
            <tbody>
                {data.length > 0 ? configRows() :
                    <ZeroRecord message={zeroRecord} colSpan={columns.length} />
                }
            </tbody>
        </table>
    );
};

Table.propTypes = {
    columns: PropTypes.array,
    data: PropTypes.array,
    configTable: PropTypes.shape({
        columnDefinition: PropTypes.any,
        columnStyle: PropTypes.any,
    }),
    tableClass: PropTypes.string,
};

Table.defaultProps = {
    columns: [],
    data: [],
    configTable: { columnDefinition: {}, columnStyle: {} },
    tableClass: 'table',
};

/** ******************************************************* */
/** Componente TableRow */
/** ******************************************************* */

const TableRow = ({ data }) => {
    return <tr>
        {data.map((obj, index) => (
            <td key={index} style={obj.style}>{obj.value}</td>
        ))}
    </tr>
};

TableRow.propTypes = {
    data: PropTypes.array,
};

/**
 * data: [{value: "", style: {...} }, {...}, ...]
 * style is the default React style object
 */
TableRow.defaultProps = {
    data: [],
};

const ZeroRecord = ({ colSpan, message }) => {
    return <tr><td colSpan={colSpan}>{message}</td></tr>
}

ZeroRecord.defaultProps = {
    colSpan: 1,
    message: 'No se han encontrado resultados.'
}

export default Table;