import React from 'react';
import { apiNewSuscription, apiGetSingleSuscription, apiUpdateSuscripcion } from '../../API/api';
import { withRouter } from 'react-router-dom';
import Switch from "react-switch";

const NewResident = (props) => {
  const { location, showLoader, NotificationManager } = props;

  const isEditMode = location.pathname.includes("editar");
  const [suscription, setSuscription] = React.useState({ nombre: '', precio: '', codigo: '', plan_familiar: false, numero_miembros_plan: 0, protegida: false, topics: '' });
  const [originalSuscription, setOriginal] = React.useState({ protegida: false });
  React.useEffect(() => {
    if (isEditMode) {
      showLoader(true);
      apiGetSingleSuscription(props.match.params.id)
        .then(response => {
          if (!response.message) {
            setSuscription(response);
            setOriginal(response);
          }
        })
        .finally(() => {
          showLoader(false);
        })
    }
  }, [isEditMode, props.match.params.id, showLoader]);

  const handleChangeSuscriptionValue = (e) => {
    const { name, value } = e.target;
    setSuscription({ ...suscription, [name]: value });
  }

  const goBack = () => {
    props.history.goBack();
  }

  const submitSuscription = (e) => {
    e.preventDefault();
    if (e.target.checkValidity()) {
      showLoader(true);
      if (isEditMode) {
        const { activo, id_suscripcion, updatedAt, createdAt, horas_mes, ...sus } = suscription;
        apiUpdateSuscripcion(id_suscripcion, sus)
          .then(response => {
            if (!response.message) {
              setSuscription(response);
              NotificationManager.success('Datos de suscripcion actualizados.', '¡Exito!', 5000);
            }
            else {
              NotificationManager.error('Ocurrió un error al guardar los datos.', '¡Error!', 5000);
            }
          })
          .catch(() => {
            NotificationManager.error('Ocurrió un error al guardar los datos.', '¡Error!', 5000);
          })
          .finally(() => {
            showLoader(false);
          })
      } else {
        apiNewSuscription(suscription)
          .then(response => {
            if (!response.message) {
              props.history.push("/suscripciones/editar/" + response.id_suscripcion);
              NotificationManager.success('Datos de suscripcion actualizados.', '¡Exito!', 5000);
            }
            else {
              NotificationManager.error('Ocurrió un error al guardar los datos.', '¡Error!', 5000);
            }
          })
          .catch(() => {
            NotificationManager.error('Ocurrió un error al guardar los datos.', '¡Error!', 5000);
          })
          .finally(() => {
            showLoader(false);
          })
      }
    }
  }

  const changeSwitch = (name, value) => {
    setSuscription({ ...suscription, [name]: value });
  }

  return <div>
    <div className="mg-top">
      <h1><i className="fas fa-id-card" /> Suscripciones
            </h1>
    </div>
    <div className="card mg-top">
      <div className="card-body">
        <h3 onClick={goBack}><i className="fas fa-arrow-left" /> {isEditMode ? "Editar" : 'Nueva'} suscripción</h3>

        <form onSubmit={submitSuscription}>

          <div className="mg-top row">
            <div className="col-md-4 form-group">
              <label className="title">Código</label>
              <input className="form-control" name="codigo" value={suscription.codigo} onChange={handleChangeSuscriptionValue} placeholder="Código" required />
            </div>
            <div className="col-md-4 form-group">
              <label className="title">Nombre</label>
              <input className="form-control" name="nombre" value={suscription.nombre} onChange={handleChangeSuscriptionValue} placeholder="Nombre" required />
            </div>
            <div className="col-md-4 form-group">
              <label className="title">Precio</label>
              <input type="number" className="form-control" name="precio" value={suscription.precio} onChange={handleChangeSuscriptionValue} placeholder="precio" required />
            </div>
            <div className="col-md-4 form-group">
              <label className="title">No. de residentes</label>
              <input type="number" className="form-control" name="numero_miembros_plan" value={suscription.numero_miembros_plan} onChange={handleChangeSuscriptionValue} placeholder="No. residentes" required />
            </div>
            <div className="col-md-4 form-group">
              <label className="title">Tema de notificación</label>
              <input className="form-control" name="topics" value={suscription.topics} onChange={handleChangeSuscriptionValue} placeholder="Tema de notificación" required />
            </div>
            <div className="col-12"></div>
            <div className="col-md-4 form-group">
              <p><label className="title">Plan familiar</label></p>
              <Switch
                checked={suscription.plan_familiar}
                onChange={(value) => changeSwitch('plan_familiar', value)}
                onColor="#1DC775"
                offColor="#DD6E07"
                onHandleColor="#E0E0E0"
                handleDiameter={20}
                uncheckedIcon={false}
                checkedIcon={false}
                className="react-switch"

              />
            </div>
            <div className="col-md-4 form-group">

              {
                isEditMode && originalSuscription.protegida ?
                  <React.Fragment>
                    <p style={{ marginBottom: ".2em" }}><label className="title">Protegida</label></p>
                    <label>Ésta suscripción esta protegida</label>
                  </React.Fragment>
                  :
                  <React.Fragment>
                    <p><label className="title">Protegida</label></p>
                    <Switch
                      checked={suscription.protegida}
                      onChange={(value) => changeSwitch('protegida', value)}
                      onColor="#1DC775"
                      offColor="#DD6E07"
                      onHandleColor="#E0E0E0"
                      handleDiameter={20}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      className="react-switch"
                    />
                  </React.Fragment>
              }
            </div>
          </div>
          <div className="mg-top tac">
            <button type="submit" className="btn btn-success">Guardar suscripcion</button>
          </div>
        </form>
      </div>

    </div>
  </div>
}

export default withRouter(NewResident);