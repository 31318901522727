import React from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

const TypeaheadCustomized = (props) => {

    const [state, setState] = React.useState(props);

    const { id, labelKey, name, multiple, minLength, onChange,
        isLoading, filterBy, selectHintOnEnter, clearButton,
        searchText, placeholder, options, onSearch,
        defaultSelected, renderMenuItemChildren, inputProps
    } = state;

    React.useEffect(() => {
        setState(props);
    }, [props]);

    return defaultSelected[0][labelKey] !== '.' ? < AsyncTypeahead
        id={id}
        labelKey={labelKey}
        name={name}
        multiple={multiple}
        minLength={minLength}
        onChange={onChange}
        isLoading={isLoading}
        filterBy={filterBy}
        selectHintOnEnter={selectHintOnEnter}
        clearButton={clearButton}
        searchText={searchText}
        placeholder={placeholder}
        options={options}
        onSearch={onSearch}
        defaultSelected={defaultSelected}
        renderMenuItemChildren={renderMenuItemChildren}
        inputProps={inputProps}
    />
        : <React.Fragment></React.Fragment>

}

export default TypeaheadCustomized