import React from "react";
import { withRouter } from "react-router-dom";
import {
  apiNewResidente,
  apiUpdateResidente,
  apiGetSingleResidente,
  apiGetResidentes,
  apiGetSuscriptions,
} from "../../API/api";
import moment from "moment";
import DateTime from "react-datetime";
import Switch from "react-switch";
import TypeaheadCustomized from "./typeaheadCustomized";

const SET_TYPEAHEAD_SUSCRIPCION = "SET_TYPEAHEAD_SUSCRIPCION",
  SET_TYPEAHEAD_TITULAR = "SET_TYPEAHEAD_TITULAR",
  SET_DATA_EDIT_MODE = "SET_DATA_EDIT_MODE",
  SET_RESIDENTE = "SET_RESIDENTE",
  SET_SUSCRIPCION = "SET_SUSCRIPCION",
  SET_PASSWORD = "SET_PASSWORD";

const reducer = (state, action) => {
  switch (action.type) {
    case SET_TYPEAHEAD_SUSCRIPCION:
      return { ...state, typeaheadSuscripcion: action.payload };
    case SET_TYPEAHEAD_TITULAR:
      return { ...state, typeaheadTitular: action.payload };
    case SET_DATA_EDIT_MODE:
      return { ...state, ...action.payload };
    case SET_RESIDENTE:
      return { ...state, residente: action.payload };
    case SET_SUSCRIPCION:
      return { ...state, suscripcion: action.payload };
    case SET_PASSWORD:
      return { ...state, password: action.payload };
    default:
      break;
  }
};

const ShowPasswordMessage = ({ match }) => {
  const message = match
    ? "Las contraseñas coinciden."
    : "Las contraseñas no coinciden.";
  let label = "";
  if (match !== undefined) {
    label = (
      <label className={"mg-top " + (match ? "match" : "unmatch")}>
        <i className={match ? "fas fa-check" : "fas fa-times"} /> {message}
      </label>
    );
  }

  return label;
};

const NewResident = (props) => {
  const { location, showLoader, NotificationManager } = props;
  const isEditMode = location.pathname.includes("editar");

  const initialState = {
    residente: {
      nombres: "",
      apellidos: "",
      telefono: "",
      correo: "",
      fecha_nacimiento: moment().toISOString(),
      id_titular: null,
      numero_socio: "",
    },
    suscripcion: {
      id_suscripcion: 0,
      fecha_inicio: moment().toISOString(),
      estatus_suscripcion: 1,
    },
    password: { pswd: "", confirm: "", match: undefined },
    typeaheadSuscripcion: {
      isLoading: false,
      options: [],
      defaultSelected: [{ nombre: "." }],
    },
    typeaheadTitular: {
      isLoading: false,
      options: [],
      defaultSelected: [{ comp: "." }],
    },
  };

  const [state, dispatch] = React.useReducer(reducer, initialState);
  const {
    residente,
    suscripcion,
    password,
    typeaheadSuscripcion,
    typeaheadTitular,
  } = state;

  React.useEffect(() => {
    if (isEditMode) {
      showLoader(true);
      apiGetSingleResidente(props.match.params.id)
        .then((response) => {
          if (!response.message) {
            const { residente, suscripcion } = response;
            let obj = {
              residente: {
                ...residente,
                fecha_nacimiento: residente.fecha_nacimiento
                  ? moment(residente.fecha_nacimiento).toISOString()
                  : moment().toISOString(),
              },
              typeaheadTitular: {
                ...typeaheadTitular,
                defaultSelected: [
                  {
                    ...residente.titular,
                    comp: residente.titular
                      ? residente.titular.codigo_residente +
                        " - " +
                        residente.titular.nombres +
                        " " +
                        residente.titular.apellidos
                      : "",
                  },
                ],
              },
            };
            if (suscripcion) {
              obj.typeaheadSuscripcion = {
                ...typeaheadSuscripcion,
                options: [suscripcion.detalle_suscripcion],
                defaultSelected: [suscripcion.detalle_suscripcion],
              };
              obj.suscripcion = {
                id_suscripcion: suscripcion.detalle_suscripcion.id_suscripcion,
                estatus_suscripcion: suscripcion.estatus_suscripcion,
                fecha_inicio: suscripcion.fecha_inicio
                  ? moment(suscripcion.fecha_inicio, "YYYY-MM-DD").toISOString()
                  : moment().toISOString(),
              };
            }
            dispatch({ type: SET_DATA_EDIT_MODE, payload: obj });
          }
        })
        .catch()
        .finally(() => {
          showLoader(false);
        });
    } else {
      dispatch({
        type: SET_TYPEAHEAD_SUSCRIPCION,
        payload: { ...typeaheadSuscripcion, defaultSelected: [{ nombre: "" }] },
      });
      dispatch({
        type: SET_TYPEAHEAD_TITULAR,
        payload: {
          ...typeaheadTitular,
          defaultSelected: [
            { id_residente: 0, nombres: "", apellidos: "", comp: "" },
          ],
        },
      });
    }
  }, [isEditMode, props.match.params.id, showLoader]);

  const handleChangeResidenteValue = (e) => {
    const { name, value } = e.target;
    if (name === "id_titular" && parseInt(value, 10) === 0) {
      dispatch({
        type: SET_RESIDENTE,
        payload: { ...residente, [name]: null },
      });
    } else {
      dispatch({
        type: SET_RESIDENTE,
        payload: { ...residente, [name]: value },
      });
    }
  };

  const handleChangeSuscripcion = (e) => {
    const { name, value } = e.target;
    dispatch({
      type: SET_SUSCRIPCION,
      payload: { ...suscripcion, [name]: value },
    });
  };

  const changeSwitch = (name, value) => {
    dispatch({
      type: SET_SUSCRIPCION,
      payload: { ...suscripcion, [name]: value ? 1 : 2 },
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (e.target.checkValidity()) {
      showLoader(true);
      if (isEditMode) {
        const {
          createdAt,
          titular,
          updatedAt,
          id_residente,
          codigo_residente,
          id_suscripcion_residente,
          ...resi
        } = residente;
        if (!resi.id_titular) {
          delete resi.id_titular;
        }
        const obj = { residente: resi };
        if (suscripcion.id_suscripcion) {
          obj.suscripcion = {
            estatus_suscripcion: suscripcion.estatus_suscripcion,
            id_suscripcion: suscripcion.id_suscripcion,
            fecha_inicio: suscripcion.fecha_inicio,
          };
        }
        apiUpdateResidente(id_residente, obj)
          .then((response) => {
            if (!response.message) {
              const { residente, suscripcion } = response;
              let obj = { residente };
              if (suscripcion) {
                obj.typeaheadSuscripcion = {
                  ...typeaheadSuscripcion,
                  options: [suscripcion.detalle_suscripcion],
                  defaultSelected: [suscripcion.detalle_suscripcion],
                };
                obj.suscripcion = {
                  id_suscripcion:
                    suscripcion.detalle_suscripcion.id_suscripcion,
                  estatus_suscripcion: suscripcion.estatus_suscripcion,
                  fecha_inicio: moment(
                    suscripcion.fecha_inicio,
                    "YYYY-MM-DD"
                  ).toISOString(),
                };
              }
              dispatch({ type: SET_DATA_EDIT_MODE, payload: obj });
              NotificationManager.success(
                "Datos de residente actualizados.",
                "¡Exito!",
                5000
              );
            } else {
              NotificationManager.error(response.message, "¡Error!", 5000);
            }
          })
          .catch(() => {
            NotificationManager.error(
              "Ocurrió un error al guardar los datos.",
              "¡Error!",
              5000
            );
          })
          .finally(() => {
            showLoader(false);
          });
      } else {
        if (!residente.id_titular) {
          delete residente.id_titular;
        }
        const obj = { residente };
        if (!residente.id_titular) {
          obj.suscripcion = suscripcion;
        }
        apiNewResidente(obj)
          .then((response) => {
            if (!response.message) {
              NotificationManager.success(
                "Usuario creado correctamente.",
                "¡Éxito!",
                5000
              );
              props.history.push(
                "/residentes/detalles/" + response.residente.id_residente
              );
            } else {
              NotificationManager.error(response.message, "¡Error!", 5000);
            }
          })
          .catch(() => {
            NotificationManager.error(
              "Ocurrió un error al guardar los datos.",
              "¡Error!",
              5000
            );
          })
          .finally(() => {
            showLoader(false);
          });
      }
    }
  };

  const ChangePassword = (e) => {
    const { name, value } = e.target;

    if (name === "confirm") {
      if (password.pswd.length >= 4) {
        dispatch({
          type: SET_PASSWORD,
          payload: {
            ...password,
            [name]: value,
            match: password.pswd === value,
          },
        });
      } else {
        dispatch({
          type: SET_PASSWORD,
          payload: { ...password, [name]: value, match: undefined },
        });
      }
    } else {
      if (password.confirm.length >= 4) {
        dispatch({
          type: SET_PASSWORD,
          payload: {
            ...password,
            [name]: value,
            match: password.confirm === value,
          },
        });
      } else {
        dispatch({
          type: SET_PASSWORD,
          payload: { ...password, [name]: value, match: undefined },
        });
      }
    }
  };

  const getSuscriptions = (search) => {
    dispatch({
      type: SET_TYPEAHEAD_SUSCRIPCION,
      payload: { ...typeaheadSuscripcion, isLoading: true },
    });

    apiGetSuscriptions({ search })
      .then((response) => {
        dispatch({
          type: SET_TYPEAHEAD_SUSCRIPCION,
          payload: {
            ...typeaheadSuscripcion,
            isLoading: false,
            options: response.rows,
          },
        });
      })
      .catch(console.error);
  };

  const getUsers = (search) => {
    dispatch({
      type: SET_TYPEAHEAD_TITULAR,
      payload: { ...typeaheadTitular, isLoading: true },
    });

    apiGetResidentes({ search })
      .then((response) => {
        dispatch({
          type: SET_TYPEAHEAD_TITULAR,
          payload: {
            ...typeaheadTitular,
            isLoading: false,
            options: response.rows,
          },
        });
      })
      .catch(console.error);
  };

  const handleSubmitPassword = (e) => {
    e.preventDefault();
    if (e.target.checkValidity()) {
      if (password.match) {
        showLoader(true);
        apiUpdateResidente(residente.id_residente, {
          residente: { password: password.pswd },
        })
          .then((response) => {
            if (!response.message) {
              dispatch({
                type: SET_PASSWORD,
                payload: { pswd: "", confirm: "", match: undefined },
              });
              NotificationManager.success(
                "Contraseña actualizada.",
                "¡Éxito!",
                5000
              );
            } else {
              NotificationManager.error(
                "Ocurrió un error al actualizar la contraseña.",
                "Error",
                5000
              );
            }
          })
          .finally(() => {
            showLoader(false);
          });
      } else {
        NotificationManager.error(
          "Las contraseñas no coinciden",
          "Error",
          5000
        );
      }
    }
  };

  const valid = (current) => {
    const day = moment().add(-1, "days");
    return current.isAfter(day);
  };

  const changeDate = (value) => {
    dispatch({
      type: SET_SUSCRIPCION,
      payload: { ...suscripcion, fecha_inicio: moment(value).toISOString() },
    });
  };

  const goBack = () => {
    props.history.goBack();
  };

  return (
    <div>
      <div className="mg-top">
        <h1>
          <i className="fas fa-users" /> Usuarios
        </h1>
      </div>
      <div className="mg-top card">
        <div className="card-body">
          <h3>
            <i className="fas fa-arrow-left" onClick={goBack} />{" "}
            {isEditMode ? "Editar" : "Nuevo"} usuario
          </h3>

          <form onSubmit={handleSubmit}>
            <div className="mg-top">
              <h5>Datos Personales</h5>
            </div>
            <hr />

            <div className="mg-top row">
              <div className="col-md-4 form-group">
                <label className="title">Número de socio</label>
                <input
                  className="form-control"
                  name="numero_socio"
                  value={residente.numero_socio}
                  onChange={handleChangeResidenteValue}
                  placeholder="No. de socio"
                />
              </div>
              <div className="col-md-4 form-group">
                <label className="title">Nombre(s)</label>
                <input
                  className="form-control"
                  placeholder="Nombres"
                  name="nombres"
                  value={residente.nombres}
                  onChange={handleChangeResidenteValue}
                  required
                />
              </div>
              <div className="col-md-4 form-group">
                <label className="title">Apellido(s)</label>
                <input
                  className="form-control"
                  placeholder="Apellidos"
                  name="apellidos"
                  value={residente.apellidos}
                  onChange={handleChangeResidenteValue}
                  required
                />
              </div>
              <div className="col-md-4 form-group">
                <label className="title">Fecha de nacimiento</label>
                <DateTime
                  closeOnSelect={true}
                  dateFormat={"DD/MMM/YYYY"}
                  placeholder="seleccione fecha"
                  timeFormat={false}
                  // isValidDate={valid}
                  onChange={(moment) =>
                    handleChangeResidenteValue({
                      target: { name: "fecha_nacimiento", value: moment },
                    })
                  }
                  value={moment(residente.fecha_nacimiento).format(
                    "DD/MMM/YYYY"
                  )}
                  inputProps={{ required: "required" }}
                />
              </div>
              <div className="col-md-4 form-group">
                <label className="title">Teléfono</label>
                <input
                  className="form-control"
                  placeholder="Teléfono"
                  name="telefono"
                  value={residente.telefono}
                  onChange={handleChangeResidenteValue}
                  required
                />
              </div>

              <div className="col-md-4 form-group">
                <label className="title">Correo Electrónico</label>
                <input
                  className="form-control"
                  placeholder="Correo electrónico"
                  name="correo"
                  value={residente.correo}
                  onChange={handleChangeResidenteValue}
                  required
                />
              </div>

              <div className="col-md-4 form-group">
                <label className="title">Titular </label>
                <TypeaheadCustomized
                  id={"AsyncTypeahead"}
                  labelKey="comp"
                  name="id_titular"
                  multiple={false}
                  minLength={3}
                  onChange={(value) => {
                    let obj = { target: { name: "id_titular", value: 0 } };
                    if (value.length > 0) {
                      obj.target.value = value[0].id_residente;
                    }
                    handleChangeResidenteValue(obj);
                  }}
                  isLoading={typeaheadTitular.isLoading}
                  filterBy={["nombres", "id_residente"]}
                  selectHintOnEnter={true}
                  clearButton={true}
                  searchText="Buscando..."
                  placeholder="Buscar Titular"
                  options={typeaheadTitular.options.map((r) => ({
                    ...r,
                    comp:
                      r.codigo_residente +
                      " - " +
                      r.nombres +
                      " " +
                      r.apellidos,
                  }))}
                  onSearch={(query) => getUsers(query)}
                  defaultSelected={typeaheadTitular.defaultSelected}
                  renderMenuItemChildren={(option, props) => (
                    <div key={option.id_residente}>
                      {option.codigo_residente} -{" "}
                      {option.nombres + " " + option.apellidos}
                    </div>
                  )}
                />
              </div>
              {isEditMode && residente.id_titular === null && (
                <div className="col-md-4 form-group mg-top">
                  <label className="title">Este residente es titular</label>
                </div>
              )}
            </div>
            <div className="mg-top">
              <h5>Datos suscripción</h5>
            </div>
            <hr />
            <div className="row">
              <div className="col-md-4 form-group">
                <label className="title">Suscripción</label>
                <TypeaheadCustomized
                  id={"AsyncTypeahead"}
                  labelKey="nombre"
                  name="id_suscripcion"
                  multiple={false}
                  minLength={3}
                  onChange={(value) => {
                    let obj = { target: { name: "id_suscripcion", value: 0 } };
                    if (value.length > 0) {
                      obj.target.value = value[0].id_suscripcion;
                    }
                    handleChangeSuscripcion(obj);
                  }}
                  isLoading={typeaheadSuscripcion.isLoading}
                  filterBy={["nombre", "id_suscripcion"]}
                  selectHintOnEnter={true}
                  clearButton={residente.id_titular > 0 ? false : true}
                  searchText="Buscando..."
                  placeholder="Cambiar suscripción"
                  options={typeaheadSuscripcion.options}
                  onSearch={(query) => getSuscriptions(query)}
                  defaultSelected={typeaheadSuscripcion.defaultSelected}
                  renderMenuItemChildren={(option, props) => (
                    <div key={option.id_suscripcion}>{option.nombre}</div>
                  )}
                  inputProps={{
                    disabled: residente.id_titular > 0 ? true : false,
                  }}
                />
              </div>
              {/* <div className="col-md-4 form-group">
                <label className="title">Fecha de inicio suscripción</label>
                <DateTime
                  closeOnSelect={true}
                  dateFormat={"DD/MMM/YYYY"}
                  placeholder="seleccione fecha"
                  timeFormat={false}
                  isValidDate={valid}
                  onChange={(moment) => changeDate(moment)}
                  value={moment(suscripcion.fecha_inicio).format("DD/MMM/YYYY")}
                  inputProps={{
                    required: "required",
                    disabled: residente.id_titular > 0 ? true : false,
                  }}
                  //   () => {
                  //     let obj = { required: "required", disabled: false }
                  //     if (residente.id_titular) {
                  //       obj.disabled = true;
                  //     }
                  //     return obj;
                  //   }
                  // }
                />
              </div> */}

              <div className="col-md-4 form-group">
                <label className="title">Estado suscripción</label>
                <div className="row">
                  <div className="col-3">
                    <Switch
                      checked={
                        suscripcion.estatus_suscripcion === 1 ? true : false
                      }
                      onChange={(value) =>
                        changeSwitch("estatus_suscripcion", value)
                      }
                      onColor="#1DC775"
                      offColor="#DD6E07"
                      onHandleColor="#E0E0E0"
                      handleDiameter={20}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      className="react-switch"
                      id={"Estado"}
                      disabled={residente.id_titular > 0 ? true : false}
                    />
                  </div>
                  <div className="col-9">
                    <label>
                      {suscripcion.estatus_suscripcion === 1
                        ? "Regular"
                        : "Pago pendiete"}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="mg-top tac">
              <button type="submit" className="btn btn-success">
                Guardar residente
              </button>
            </div>
          </form>
        </div>
      </div>
      {isEditMode && (
        <div className="mg-top card">
          <div className="card-body">
            <h5>Editar contraseña</h5>
            <hr />
            <form onSubmit={handleSubmitPassword}>
              <div className="row">
                <div className="col-md-4 form-group">
                  <label className="title">Contraseña</label>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Escriba nueva contraseña"
                    name="pswd"
                    value={password.pswd}
                    onChange={ChangePassword}
                    required
                  />
                </div>
                <div className="col-md-4 form-group">
                  <label className="title">Confirmar contraseña</label>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Confirmar contraseña"
                    name="confirm"
                    value={password.confirm}
                    onChange={ChangePassword}
                    required
                  />
                </div>
                <div className="col-md-4 form-group">
                  <ShowPasswordMessage match={password.match} />
                </div>
              </div>
              <div className="tac mg-top">
                <button type="submit" className="btn btn-success">
                  Guardar contraseña
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default withRouter(NewResident);
