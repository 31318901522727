import React from 'react';
import PropTypes from 'prop-types';

const MyEvent = ({ event }) => {
    const { title, day } = event;

    return <div className="rc-event-container">
        <label title={title + ' ' + day}>
            {title} {day}</label>
    </div>
}

MyEvent.propTypes  = {
    event: PropTypes.object,
}

MyEvent.defaultPrps = {
    event: { title: "", day: '' }
}

export default MyEvent;