import {
  baseUrl,
  GET,
  POST,
  PUT,
  makeUrlGET,
  CreateAuthRequest,
} from "./constan";

export const apiSignIn = async (body) => {
  const url = baseUrl + "auth/login";

  const request = {
    method: POST,
    headers: new Headers({ "Content-Type": "application/json" }),
    body: JSON.stringify(body),
  };

  return fetch(url, request).then((response) => response.json());
};

//region Residencias
let urlAPI = baseUrl + "residencias";
export const apiGetResidencias = async (obj) => {
  let url = `${urlAPI}?` + makeUrlGET(obj);
  const request = await CreateAuthRequest(GET, null, true);
  return fetch(url, request).then((response) => response.json());
};

export const apiGetSingleResidencia = async (id) => {
  let url = `${urlAPI}/${id}`;
  const request = await CreateAuthRequest(GET, null, true);
  return fetch(url, request).then((response) => response.json());
};

export const apiNewResidencia = async (obj) => {
  const request = await CreateAuthRequest(POST, obj, true);
  return fetch(urlAPI, request).then((response) => response.json());
};

export const apiUpdateResidencia = async (id, obj) => {
  let url = `${urlAPI}/${id}`;
  const request = await CreateAuthRequest(PUT, obj, true);
  return fetch(url, request).then((response) => response.json());
};
//endregion

//region Suscripciones
export const apiGetSuscriptions = async (obj) => {
  let url = `${baseUrl}suscripcion?` + makeUrlGET(obj);
  const request = await CreateAuthRequest(GET, null, true);
  return fetch(url, request).then((response) => response.json());
};

export const apiGetSingleSuscription = async (id) => {
  let url = `${baseUrl}suscripcion/${id}`;
  const request = await CreateAuthRequest(GET, null, true);
  return fetch(url, request).then((response) => response.json());
};

export const apiNewSuscription = async (obj) => {
  let url = `${baseUrl}suscripcion`;
  const request = await CreateAuthRequest(POST, obj, true);
  return fetch(url, request).then((response) => response.json());
};

export const apiUpdateSuscripcion = async (id, obj) => {
  let url = `${baseUrl}suscripcion/${id}`;
  const request = await CreateAuthRequest(PUT, obj, true);
  return fetch(url, request).then((response) => response.json());
};

//endregion

//region residentes
export const apiGetResidentes = async (obj) => {
  let url = `${baseUrl}residentes?` + makeUrlGET(obj);
  const request = await CreateAuthRequest(GET, null, true);
  return fetch(url, request).then((response) => response.json());
};

export const apiGetSingleResidente = async (id) => {
  let url = `${baseUrl}residentes/${id}`;
  const request = await CreateAuthRequest(GET, null, true);
  return fetch(url, request).then((response) => response.json());
};

export const apiGetResidentePayments = async (id) => {
  let url = `${baseUrl}residentes/${id}/suscripciones/movimientos`;
  const request =await CreateAuthRequest(GET, null, true);
  return fetch(url, request).then((response) => response.json());
}

export const apiNewResidente = async (obj) => {
  const request = await CreateAuthRequest(POST, obj, true);
  return fetch(baseUrl + "residentes", request).then((response) =>
    response.json()
  );
};

export const apiUpdateResidente = async (id, obj) => {
  let url = `${baseUrl}residentes/${id}`;
  const request = await CreateAuthRequest(PUT, obj, true);
  return fetch(url, request).then((response) => response.json());
};

export const apiUpdateResidenteSuscripcion = async (id, obj) => {
  let url = `${baseUrl}residentes/${id}/suscripciones/pagos`;
  const request = await CreateAuthRequest(POST, obj, true);
  return fetch(url, request).then((response) => response.json());
}

//endregion

//region reservaciones
export const apiGetTiposJuego = async (obj) => {
  let url = `${baseUrl}tipojuego?` + makeUrlGET(obj);
  const request = await CreateAuthRequest(GET, null, true);
  return fetch(url, request).then((response) => response.json());
};

export const apiNewReservacion = async (obj) => {
  const request = await CreateAuthRequest(POST, obj, true);
  return fetch(baseUrl + "reservaciones", request).then((response) =>
    response.json()
  );
};

export const apiNewClase = async (obj) => {
  const request = await CreateAuthRequest(POST, obj, true);
  return fetch(baseUrl + "reservaciones/clase", request).then((response) =>
    response.json()
  );
};

export const apiCancelClase = async (obj) => {
  let url = `${baseUrl}reservaciones/cancelar/clase`;
  const request = await CreateAuthRequest(POST, obj, true);
  return fetch(url, request)
      .then(response => response.json())
}
//endregion
