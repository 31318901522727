import React from 'react';
import { Modal } from 'react-bootstrap';


const ModalActivar = (props) => {
    const { show, handleClose, confirm, name, word } = props;


    const handleCloseModal = () => {
        handleClose();
    }

    const handleConfirm = () => {
        confirm();
    }


    return (
        <Modal show={show} onHide={handleCloseModal} centered
            dialogClassName="modal-20w">
            <Modal.Header closeButton>
                <Modal.Title>Confirmar {word} residente</Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <h5> ¿Quieres {word} a {name}?</h5>


            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-secondary" onClick={handleCloseModal}>No, cancelar</button>
                <button className="btn btn-danger" onClick={handleConfirm}>Si, {word}</button>
            </Modal.Footer>
        </Modal>
    );
}

ModalActivar.defaultProps = {
    show: false,
    name: '',
    word: false,
    confirm: () => { },
    handleClose: () => { },
}

export default ModalActivar;