
import { baseUrl, GET, POST, PUT, makeUrlGET, CreateAuthRequest } from "./constan"


//region Residencias
let urlAPI = baseUrl + "areas";
export const apiGetCanchas = async (obj) => {
    let url = `${urlAPI}?` + makeUrlGET(obj);
    const request = await CreateAuthRequest(GET, null, true);
    return fetch(url, request)
        .then(response => response.json())
}

export const apiGetSingleCancha = async (id) => {
    let url = `${urlAPI}/${id}`;
    const request = await CreateAuthRequest(GET, null, true);
    return fetch(url, request)
        .then(response => response.json())
}

export const apiNewCancha = async (obj) => {
    const request = await CreateAuthRequest(POST, obj, true);
    return fetch(urlAPI, request)
        .then(response => response.json())
}

export const apiUpdateCancha = async (id, obj) => {
    let url = `${urlAPI}/${id}`;
    const request = await CreateAuthRequest(PUT, obj, true);
    return fetch(url, request)
        .then(response => response.json())
}

export const apiGetReservaciones = async (id, obj) => {
    let url = `${urlAPI}/reservaciones/${id}?` + makeUrlGET(obj);
    const request = await CreateAuthRequest(GET, null, true);
    return fetch(url, request)
        .then(response => response.json())
}

export const apiCancelReservaciones = async (id) => {
    let url = `${urlAPI}/reservaciones/${id}`;
    const request = await CreateAuthRequest(PUT, null, true);
    return fetch(url, request)
        .then(response => response.json())
}
//endregion
