import React from 'react';
import { Modal } from 'react-bootstrap';

const ModalConfirm = ({ show = false, handleClose, handleConfirm, message }) => {
  return (
    <Modal show={show} onHide={handleClose} centered dialogClassName="modal-20w">
      <Modal.Header closeButton>
        <Modal.Title>Confirmar</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="tac" style={{ fontSize: '20px' }}>
          {message}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary" onClick={handleClose}>
          Cancelar
        </button>
        <button className="btn btn-danger" onClick={handleConfirm}>
          Confirmar
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalConfirm;
