import React from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import ResidentsList from '../Usuarios/residentsList';
import ResidentNew from '../Usuarios/newResident';
import ResidentDetail from '../Usuarios/residentDetails';
import SuscripcionesList from '../Suscriptions/suscriptionList';
import SuscripcionesNew from '../Suscriptions/suscriptionNew';
import Canchas from '../Areas/areas';
import NewCancha from '../Areas/newEditArea';
import Notificaciones from '../Notifications/notificaciones';
import NewNotificacion from '../Notifications/newNotification';
import NewReservacion from '../reservaciones/newReservacion';
import Avisos from '../Avisos/avisos';
import NewAviso from '../Avisos/newAviso';

const BoxContainer = (props) => {

  return <Switch >
    <Redirect exact={true} from='/' to='/monitor' />
    <Route path='/usuarios' exact={true} component={() => <ResidentsList {...props} />} />
    <Route path='/usuarios/nuevo' exact={true} component={() => <ResidentNew {...props} />} />
    <Route path="/usuarios/editar/:id" component={() => <ResidentNew {...props} />} />
    <Route path="/usuarios/detalles/:id" component={() => <ResidentDetail {...props} />} />
    <Route path='/suscripciones' exact={true} component={() => <SuscripcionesList {...props} />} />
    <Route path='/suscripciones/nueva' exact={true} component={() => <SuscripcionesNew {...props} />} />
    <Route path='/suscripciones/editar/:id' exact={true} component={() => <SuscripcionesNew {...props} />} />
    <Route path='/canchas' exact={true} component={() => <Canchas {...props} />} />
    <Route path='/canchas/nueva' exact={true} component={() => <NewCancha {...props} />} />
    <Route path='/canchas/editar/:id' exact={true} component={() => <NewCancha {...props} />} />
    <Route path='/reservaciones/nueva' exact={true} component={() => <NewReservacion {...props} />} />
    <Route path='/notificaciones' exact={true} component={() => <Notificaciones {...props} />} />
    <Route path='/notificaciones/nueva' exact={true} component={() => <NewNotificacion {...props} />} />
    <Route path='/notificaciones/detalle/:id' exact={true} component={() => <Notificaciones {...props} />} />
    <Route path='/avisos' exact={true} component={() => <Avisos {...props} />} />
    <Route path='/avisos/nuevo' exact={true} component={() => <NewAviso {...props} />} />
    {/* <Route path='/avisos/detalle/:id' exact={true} component={() => <Notificaciones {...props} />} /> */}
    <Redirect to='/usuarios' />
  </Switch >
}


export default BoxContainer;

